export const IdentifierType = {
    NNZAF: "South African Identity Number",
    PPN: "Passport number"
}

export const CASE_STATES = {
    "New case": {
        before_label: "Acknowledge",
        after_label: "Acknowledged",
        current_state: "New case",
        next_state: "Acknowledged",
    },
    "Acknowledged": {
        before_label: "Mobile incident",
        after_label: "Enroute...",
        current_state: "Acknowledged",
        next_state: "Mobile incident",
    },
    "Mobile incident": {
        before_label: "At incident",
        after_label: "Arrived at incident",
        current_state: "Mobile incident",
        next_state: "At incident",
    },
    "At incident": {
        before_label: "Mobile hospital",
        after_label: "Enroute...",
        current_state: "At incident",
        next_state: "Mobile hospital",
    },
    "Mobile hospital": {
        before_label: "At hospital",
        after_label: "Arrived at hospital",
        current_state: "Mobile hospital",
        next_state: "At hospital",
    },
    "At hospital": {
        before_label: "Handover case",
        after_label: "Case handed over",
        current_state: "At hospital",
        next_state: "Awaiting handover",
    },
    "Awaiting handover": {
        before_label: "Capture patient info",
        after_label: "Capturing info...",
        current_state: "Awaiting handover",
        next_state: "Capturing patient info",
    },
    "Capturing patient info": {
        before_label: "Available for next case",
        after_label: "Now available",
        current_state: "Capturing patient info",
        next_state: "Case complete",
    },
};

export const FACILITIES = [
    {
        "facility": "Rehab",
        "lat": null,
        "lon": null
    },
    {
        "facility": "Residence",
        "lat": null,
        "lon": null
    },
    {
        "facility": "Provincial facility",
        "lat": null,
        "lon": null
    },
    {
        "facility": "Mine Medical Center",
        "lat": null,
        "lon": null
    },
    {
        "facility": "1 Military Hospital",
        "lat": -25.77846106,
        "lon": 28.161654456
    },
    {
        "facility": "2 Military Hospital",
        "lat": -34.003983333,
        "lon": 18.453816667
    },
    {
        "facility": "3 Military Hospital",
        "lat": -29.094079926,
        "lon": 26.19275701
    },
    {
        "facility": "7th Haven OAH",
        "lat": -29.596721611,
        "lon": 30.295823417
    },
    {
        "facility": "A.D Care Centre",
        "lat": -26.119444444,
        "lon": 28.082222222
    },
    {
        "facility": "Aberdeen Hospital",
        "lat": -32.475633333,
        "lon": 24.065483333
    },
    {
        "facility": "Abraham Esau Hospital",
        "lat": -31.473916667,
        "lon": 19.77325
    },
    {
        "facility": "Actonville Hospital",
        "lat": -26.231416667,
        "lon": 28.299916667
    },
    {
        "facility": "Ad care",
        "lat": -25.769110357,
        "lon": 28.338261634
    },
    {
        "facility": "Addington Hospital",
        "lat": -29.861795874,
        "lon": 31.041886432
    },
    {
        "facility": "Adelaide Hospital",
        "lat": -32.70465,
        "lon": 26.296416667
    },
    {
        "facility": "Advanced Home Care",
        "lat": -33.888494349,
        "lon": 18.572567758
    },
    {
        "facility": "Advent Haven OAH",
        "lat": -26.517804754,
        "lon": 28.296793411
    },
    {
        "facility": "Ahmed Al Kadi Hospital",
        "lat": -29.84342919,
        "lon": 30.97874241
    },
    {
        "facility": "Ahmed Neurologist",
        "lat": -23.90874902,
        "lon": 29.46181396
    },
    {
        "facility": "Akeso Clinic (Alberton)",
        "lat": -26.265555556,
        "lon": 28.120739398
    },
    {
        "facility": "Akeso Clinic (Arcadia)",
        "lat": -25.746922694,
        "lon": 28.223666697
    },
    {
        "facility": "Akeso Clinic (George)",
        "lat": -33.957109535,
        "lon": 22.450275853
    },
    {
        "facility": "Akeso Clinic (Kenilworth)",
        "lat": -26.08777966,
        "lon": 27.958466304
    },
    {
        "facility": "Akeso Clinic (Milnerton)",
        "lat": -33.869178581,
        "lon": 18.505342024
    },
    {
        "facility": "Akeso Clinic (Montrose Manor)",
        "lat": -33.995306077,
        "lon": 18.431518696
    },
    {
        "facility": "Akeso Clinic (Nelspruit)",
        "lat": -25.404087451,
        "lon": 30.983480576
    },
    {
        "facility": "Akeso Clinic (Parktown)",
        "lat": -26.182337335,
        "lon": 28.044098987
    },
    {
        "facility": "Akeso Clinic (Pietermaritzburg)",
        "lat": -29.604461671,
        "lon": 30.412553787
    },
    {
        "facility": "Akeso Clinic (Randburg)",
        "lat": -26.08775,
        "lon": 27.95815
    },
    {
        "facility": "Akeso Clinic (Umhlanga)",
        "lat": -29.713044685,
        "lon": 31.068579007
    },
    {
        "facility": "Alan Blyth Hospital",
        "lat": -33.486279905,
        "lon": 21.267909838
    },
    {
        "facility": "Albert Luthuli Hospital",
        "lat": -29.873049975,
        "lon": 30.958919665
    },
    {
        "facility": "Alexander Bay Hospital",
        "lat": -28.596016667,
        "lon": 16.483416667
    },
    {
        "facility": "Alexandra Care & Rehabilitation Centre",
        "lat": -33.932916667,
        "lon": 18.482883333
    },
    {
        "facility": "Algoa Frail Care Centre",
        "lat": -33.88010481,
        "lon": 25.488101995
    },
    {
        "facility": "Algoa Park Medi Cross",
        "lat": -33.902233333,
        "lon": 25.562466667
    },
    {
        "facility": "Aliwal North Provincial Hospital",
        "lat": -30.691388889,
        "lon": 26.693611111
    },
    {
        "facility": "All Saints Hospital",
        "lat": -31.675266667,
        "lon": 27.997433333
    },
    {
        "facility": "Allan Woodrow Park",
        "lat": -26.243398002,
        "lon": 28.318333333
    },
    {
        "facility": "ALLANPARK OAH",
        "lat": -26.078669946,
        "lon": 28.231958982
    },
    {
        "facility": "Amajuba Memorial Hospital",
        "lat": -27.352950974,
        "lon": 29.890465338
    },
    {
        "facility": "Amangwe Village",
        "lat": -28.602222222,
        "lon": 32.084316667
    },
    {
        "facility": "Amber Valley",
        "lat": -29.785277778,
        "lon": 30.415277778
    },
    {
        "facility": "Amberfield Lodge Rehab",
        "lat": -29.478858049,
        "lon": 30.223278045
    },
    {
        "facility": "Amont OAH",
        "lat": -26.916968989,
        "lon": 27.45316239
    },
    {
        "facility": "Andrew Saffy Hospital-ASM",
        "lat": -25.678055556,
        "lon": 27.528055556
    },
    {
        "facility": "Andries Vosloo Hospital",
        "lat": -32.718833333,
        "lon": 25.584516667
    },
    {
        "facility": "Anglo Hospital",
        "lat": -25.916727874,
        "lon": 29.199825392
    },
    {
        "facility": "Anthusa Health Care",
        "lat": -34.03815,
        "lon": 18.455216667
    },
    {
        "facility": "Appelbosch Hospital",
        "lat": -29.39,
        "lon": 30.843333333
    },
    {
        "facility": "Arboretum Sentrum Vir OAH",
        "lat": -27.645555556,
        "lon": 27.234166667
    },
    {
        "facility": "Arwyp Medical Centre",
        "lat": -26.106916667,
        "lon": 28.23405
    },
    {
        "facility": "Assisi Hospital",
        "lat": -36.594833333,
        "lon": 30.324183333
    },
    {
        "facility": "Athlone Day Hospital",
        "lat": -33.966183333,
        "lon": 18.505283333
    },
    {
        "facility": "Aurora Step-Down",
        "lat": -33.969858877,
        "lon": 25.561423928
    },
    {
        "facility": "Avril Elizabeth Home",
        "lat": -26.1775,
        "lon": 28.167916667
    },
    {
        "facility": "B J Kempen Hospital",
        "lat": -31.4041,
        "lon": 23.117116667
    },
    {
        "facility": "B J Vorster Hospital",
        "lat": -33.9512,
        "lon": 24.290066667
    },
    {
        "facility": "Balema Clinic",
        "lat": -25.225,
        "lon": 26.943055556
    },
    {
        "facility": "Bapong Clinic",
        "lat": -25.696068113,
        "lon": 27.68177711
    },
    {
        "facility": "Baragwanath Hospital",
        "lat": -26.260800899,
        "lon": 27.943643216
    },
    {
        "facility": "Barbarton TB Hospital",
        "lat": -25.780586125,
        "lon": 31.02842623
    },
    {
        "facility": "Barberton Hospital",
        "lat": -25.78108548,
        "lon": 31.041376335
    },
    {
        "facility": "Barkly West Hospital",
        "lat": -28.53595,
        "lon": 24.523883333
    },
    {
        "facility": "Beaufort West Hospital",
        "lat": -32.352852972,
        "lon": 22.607615945
    },
    {
        "facility": "Bedford Orthopedic Centre",
        "lat": -31.589983333,
        "lon": 28.782666667
    },
    {
        "facility": "Bedford Provincial Hospital",
        "lat": -32.681516667,
        "lon": 26.086966667
    },
    {
        "facility": "Belhar Clinic",
        "lat": -33.94385,
        "lon": 18.62285
    },
    {
        "facility": "Bell Street Hospital",
        "lat": -26.09595,
        "lon": 27.805816667
    },
    {
        "facility": "Benedictine Hospital",
        "lat": -27.9117,
        "lon": 31.647516667
    },
    {
        "facility": "Benoni Medi Cross",
        "lat": -26.161266667,
        "lon": 28.304633333
    },
    {
        "facility": "Benoni Worker's Rehab clinic",
        "lat": -26.193143119,
        "lon": 28.30615195
    },
    {
        "facility": "Bentley Park",
        "lat": -26.340277778,
        "lon": 27.501388889
    },
    {
        "facility": "Berg Medi Cross",
        "lat": -26.163048025,
        "lon": 27.952492579
    },
    {
        "facility": "Bergville Retirement Village",
        "lat": -33.942696271,
        "lon": 22.474901741
    },
    {
        "facility": "Bernice Samuel Hospital",
        "lat": -26.15278103,
        "lon": 28.666543086
    },
    {
        "facility": "Bertha Gxowa Hospital",
        "lat": -26.220688529,
        "lon": 28.164405832
    },
    {
        "facility": "Bethal Hospital",
        "lat": -26.449116667,
        "lon": 29.457833333
    },
    {
        "facility": "Bethesda Hospital",
        "lat": -27.563683333,
        "lon": 32.085983333
    },
    {
        "facility": "Bethlehem Medical Centre",
        "lat": -28.23085,
        "lon": 28.319166667
    },
    {
        "facility": "Bethlehem Provincial Hospital",
        "lat": -28.2336,
        "lon": 28.315416667
    },
    {
        "facility": "Bhubezi/Lillydale clinic",
        "lat": -24.879867465,
        "lon": 31.381146208
    },
    {
        "facility": "Bill Pickard Hospital",
        "lat": -29.664704811,
        "lon": 22.747996157
    },
    {
        "facility": "Bisho Hospital",
        "lat": -32.7617,
        "lon": 27.622633333
    },
    {
        "facility": "Bishop Lavis Day Hospital",
        "lat": -33.94891763,
        "lon": 18.581342618
    },
    {
        "facility": "Bizana Medical Centre",
        "lat": -30.859683333,
        "lon": 29.8522
    },
    {
        "facility": "Bloemcare Step-Down",
        "lat": -29.086883333,
        "lon": 26.1569
    },
    {
        "facility": "Bloemfontein Medi Cross",
        "lat": -29.108357436,
        "lon": 26.198589768
    },
    {
        "facility": "Bloemhof Hospital",
        "lat": -27.6467,
        "lon": 25.6061
    },
    {
        "facility": "Bluff Medi Cross",
        "lat": -29.907866667,
        "lon": 31.020766667
    },
    {
        "facility": "Boitumelo Hospital",
        "lat": -27.641944708,
        "lon": 27.214051205
    },
    {
        "facility": "Boksburg Benoni Hospital",
        "lat": -26.217866667,
        "lon": 28.244116667
    },
    {
        "facility": "Boksburg Medi Cross",
        "lat": -26.177833333,
        "lon": 28.255283333
    },
    {
        "facility": "Bone & Breastcare Centre",
        "lat": -26.12565,
        "lon": 28.049616667
    },
    {
        "facility": "Bongani Hospital",
        "lat": -25.041933333,
        "lon": 31.126533333
    },
    {
        "facility": "Bongani Hospital Welkom",
        "lat": -27.952315674,
        "lon": 26.786157345
    },
    {
        "facility": "Bonteheuwel Clinic",
        "lat": -33.950133333,
        "lon": 18.55405
    },
    {
        "facility": "Booth Memorial Hospital",
        "lat": -33.932666667,
        "lon": 18.414433333
    },
    {
        "facility": "Bophelong Hospital",
        "lat": -25.883888889,
        "lon": 25.656666667
    },
    {
        "facility": "Bophelong Medical Centre",
        "lat": -26.296383068,
        "lon": 28.39130161
    },
    {
        "facility": "Botlokwa Hospital",
        "lat": -23.492222222,
        "lon": 29.741944444
    },
    {
        "facility": "Botshabelo Hospital",
        "lat": -29.232597663,
        "lon": 26.716122196
    },
    {
        "facility": "Botshelong Clinic",
        "lat": -25.480555556,
        "lon": 28.094444444
    },
    {
        "facility": "Botshilu Private Hospital",
        "lat": -25.47985258,
        "lon": 28.094369845
    },
    {
        "facility": "Brackenfell Hospital",
        "lat": -33.8754,
        "lon": 18.696383333
    },
    {
        "facility": "Brackenfell Medi Cross",
        "lat": -33.879315048,
        "lon": 18.682969602
    },
    {
        "facility": "Brackenhurst Day Hospital",
        "lat": -26.314283333,
        "lon": 28.097766667
    },
    {
        "facility": "Bray Provincial Hospital",
        "lat": -25.4623,
        "lon": 23.699316667
    },
    {
        "facility": "Brewelskloof Hospital",
        "lat": -33.644671586,
        "lon": 19.457679096
    },
    {
        "facility": "Brits Provincial Hospital",
        "lat": -25.639444444,
        "lon": 27.786111111
    },
    {
        "facility": "Britstown Hospital",
        "lat": -30.587233333,
        "lon": 23.502083333
    },
    {
        "facility": "Brooklyn Surgical Centre",
        "lat": -25.7704,
        "lon": 28.23945
    },
    {
        "facility": "Brown's Farm Community Health Centre",
        "lat": -34.008416667,
        "lon": 18.5988
    },
    {
        "facility": "Bruntville Community Health Centre",
        "lat": -29.21035,
        "lon": 30.006083333
    },
    {
        "facility": "Burgersdorp Hospital",
        "lat": -30.994133333,
        "lon": 26.330866667
    },
    {
        "facility": "Busamed Private Hospital",
        "lat": -26.075125,
        "lon": 28.129998
    },
    {
        "facility": "Bushbuck Ridge Hospital",
        "lat": -24.847283333,
        "lon": 31.061083333
    },
    {
        "facility": "Butterworth Hospital",
        "lat": -32.3301,
        "lon": 28.144616667
    },
    {
        "facility": "C.N. Padudi Hospital",
        "lat": -24.026941908,
        "lon": 30.281034299
    },
    {
        "facility": "Cairn Hall Hospital",
        "lat": -29.117566667,
        "lon": 26.190216667
    },
    {
        "facility": "Cala Hospital",
        "lat": -31.522266667,
        "lon": 27.693366667
    },
    {
        "facility": "Caledon Provincial Hospital",
        "lat": -34.224062182,
        "lon": 19.433214774
    },
    {
        "facility": "Cancer Association of South Africa",
        "lat": -25.675883333,
        "lon": 28.2102
    },
    {
        "facility": "Cango Medicentre (Pty) Ltd.",
        "lat": -33.589633333,
        "lon": 22.1998
    },
    {
        "facility": "Canzibe Hospital",
        "lat": -31.67235,
        "lon": 29.033083333
    },
    {
        "facility": "Cape Eye Hospital",
        "lat": -33.898616667,
        "lon": 18.610516667
    },
    {
        "facility": "Cape Road Medi Cross",
        "lat": -33.952805088,
        "lon": 25.581908313
    },
    {
        "facility": "Cape Town International",
        "lat": -33.969444444,
        "lon": 18.605
    },
    {
        "facility": "CAPEGATE MEDICLINIC",
        "lat": -29.585376487,
        "lon": 30.408034792
    },
    {
        "facility": "Capital Haematology Hospital",
        "lat": -29.851936489,
        "lon": 30.986428725
    },
    {
        "facility": "Cardiac Clinic",
        "lat": -33.000333333,
        "lon": 27.897033333
    },
    {
        "facility": "Care Cure Clinic, Krugersdorp",
        "lat": -26.104633333,
        "lon": 27.7765
    },
    {
        "facility": "Care Cure Queenstown",
        "lat": -31.899722222,
        "lon": 26.860833333
    },
    {
        "facility": "Care Cure Rynfield",
        "lat": -26.172183376,
        "lon": 28.330420415
    },
    {
        "facility": "Carecure, Bloemfontein",
        "lat": -29.122766667,
        "lon": 26.207583333
    },
    {
        "facility": "Carecure, Vereeniging",
        "lat": -26.668633333,
        "lon": 27.929633333
    },
    {
        "facility": "Care-Kgatelopele",
        "lat": -25.83291354,
        "lon": 25.606254314
    },
    {
        "facility": "Caritas Care",
        "lat": -26.2132,
        "lon": 28.25515
    },
    {
        "facility": "Carletonville Hospital",
        "lat": -26.346416667,
        "lon": 27.395316667
    },
    {
        "facility": "Carnavon Hospital",
        "lat": -30.965303874,
        "lon": 22.132886235
    },
    {
        "facility": "Carolina Hospital",
        "lat": -26.074,
        "lon": 30.1097
    },
    {
        "facility": "Castle Carey Clinic",
        "lat": -32.297,
        "lon": 27.1515
    },
    {
        "facility": "Cathcart Hospital",
        "lat": -32.300183333,
        "lon": 27.145733333
    },
    {
        "facility": "Catherine Booth Hospital",
        "lat": -28.996944444,
        "lon": 31.133333333
    },
    {
        "facility": "Cecilia Makiwane hospital",
        "lat": -32.92955,
        "lon": 27.734833333
    },
    {
        "facility": "Central Karoo Hospital",
        "lat": -30.660319032,
        "lon": 24.007769818
    },
    {
        "facility": "Centurion Eye Institute",
        "lat": -25.846777777,
        "lon": 28.194444444
    },
    {
        "facility": "Centurion Sub-Acute",
        "lat": -25.827676843,
        "lon": 28.203483657
    },
    {
        "facility": "Ceres Hospital",
        "lat": -33.368833333,
        "lon": 19.309566667
    },
    {
        "facility": "Chaneng Clinic",
        "lat": -25.413206141,
        "lon": 27.124469542
    },
    {
        "facility": "Charles Hurwitz SANTA Rehab Centre",
        "lat": -26.279561473,
        "lon": 27.863289683
    },
    {
        "facility": "Charles James SANTA Centre",
        "lat": -30.016861891,
        "lon": 30.885810068
    },
    {
        "facility": "Charles Johnson Memorial Hospital",
        "lat": -28.21395,
        "lon": 30.675283333
    },
    {
        "facility": "Charlotte Maxeke Hospital",
        "lat": -26.175833333,
        "lon": 28.044444444
    },
    {
        "facility": "Chelsea Care Home",
        "lat": -26.130008615,
        "lon": 28.353850203
    },
    {
        "facility": "Christ The King Hospital",
        "lat": -30.1578,
        "lon": 30.061583333
    },
    {
        "facility": "Christiana Hospital",
        "lat": -27.908256603,
        "lon": 25.174808023
    },
    {
        "facility": "Church Of Scotland Hospital",
        "lat": -28.747616667,
        "lon": 30.4446
    },
    {
        "facility": "Cintocare",
        "lat": -25.784552562,
        "lon": 28.280568542
    },
    {
        "facility": "Circle Health Care",
        "lat": -29.783888889,
        "lon": 30.974722222
    },
    {
        "facility": "Citrusdal Hospital",
        "lat": -32.598623519,
        "lon": 19.017308397
    },
    {
        "facility": "City Hospital",
        "lat": -29.850706427,
        "lon": 31.016577889
    },
    {
        "facility": "CityMed",
        "lat": -29.087293757951553,
        "lon": 26.21338535546062
    },
    {
        "facility": "Clairwood Hospital",
        "lat": -29.913933333,
        "lon": 30.981816667
    },
    {
        "facility": "Clanwilliam Hospital",
        "lat": -32.19,
        "lon": 18.889183333
    },
    {
        "facility": "Claro Psychiatric Clinic",
        "lat": -33.893395749,
        "lon": 18.559667502
    },
    {
        "facility": "Clayton House",
        "lat": -26.185986575,
        "lon": 28.068452421
    },
    {
        "facility": "Clinic 3 - Soshanguve",
        "lat": -25.491111111,
        "lon": 28.094444444
    },
    {
        "facility": "Clinix Botshelong Empilweni",
        "lat": -26.344186759,
        "lon": 28.217790973
    },
    {
        "facility": "Clinix Dr SK Matseke",
        "lat": -26.258027654,
        "lon": 27.935133697
    },
    {
        "facility": "Clinix Naledi Nkanyezi",
        "lat": -26.616260196,
        "lon": 27.847691457
    },
    {
        "facility": "Clinix Phalaborwa",
        "lat": -23.937222222,
        "lon": 31.142777777
    },
    {
        "facility": "Clinix Solomom Stix Morewa",
        "lat": -26.221384445,
        "lon": 28.043444865
    },
    {
        "facility": "Clinix Tshepo Themba",
        "lat": -26.205262004,
        "lon": 27.873841726
    },
    {
        "facility": "Clinix Victoria Itokolle",
        "lat": -25.85846112,
        "lon": 25.64321247
    },
    {
        "facility": "Clocolan Provincial Hospital",
        "lat": -28.918766667,
        "lon": 27.566266667
    },
    {
        "facility": "Cloete Joubert Hospital",
        "lat": -30.966933333,
        "lon": 27.590866667
    },
    {
        "facility": "Cofimvaba Hospital",
        "lat": -31.998,
        "lon": 27.5761
    },
    {
        "facility": "Coligny General Hospital",
        "lat": -26.333633333,
        "lon": 26.320383333
    },
    {
        "facility": "Colstine Lodge Nursing Home",
        "lat": -33.978181879,
        "lon": 18.483069064
    },
    {
        "facility": "Community Health Center",
        "lat": -33.904571064,
        "lon": 18.638258916
    },
    {
        "facility": "Connie Mulder Centre",
        "lat": -26.155034698,
        "lon": 27.677075625
    },
    {
        "facility": "Connie Vorster Memorial",
        "lat": -27.7575,
        "lon": 24.813055556
    },
    {
        "facility": "Conradie Hospital",
        "lat": -33.924566667,
        "lon": 18.521233333
    },
    {
        "facility": "Constantia Park Medi Cross",
        "lat": -25.80315867,
        "lon": 28.287076259
    },
    {
        "facility": "Cormed Clinic",
        "lat": -26.703,
        "lon": 27.830666667
    },
    {
        "facility": "Corobrik Clinic",
        "lat": -29.77145,
        "lon": 31.007283333
    },
    {
        "facility": "CPT International Airport",
        "lat": -33.9708,
        "lon": 18.5972
    },
    {
        "facility": "Cradock Hospital",
        "lat": -32.166916667,
        "lon": 25.622116667
    },
    {
        "facility": "Crescent Clinic",
        "lat": -33.98936017,
        "lon": 18.467091544
    },
    {
        "facility": "Crescent Clinic, Claremont",
        "lat": -33.989433333,
        "lon": 18.467133333
    },
    {
        "facility": "Crescent Clinic, JHB",
        "lat": -26.087766292,
        "lon": 27.958492648
    },
    {
        "facility": "Crossroad Rehab",
        "lat": -25.846932107,
        "lon": 28.255726978
    },
    {
        "facility": "Cullinan Care & Rehab Centre",
        "lat": -25.666854733,
        "lon": 28.51809685
    },
    {
        "facility": "Cullinan Private Hospital",
        "lat": -25.667616667,
        "lon": 28.518033333
    },
    {
        "facility": "Curamed Kimberley",
        "lat": -28.745277778,
        "lon": 24.771111111
    },
    {
        "facility": "Curamed Thabazimbi",
        "lat": -24.598888889,
        "lon": 27.407222222
    },
    {
        "facility": "Curamed, Pretoria",
        "lat": -25.74895,
        "lon": 28.2078
    },
    {
        "facility": "Dalview Clinic",
        "lat": -26.244961908,
        "lon": 28.353833572
    },
    {
        "facility": "Dark City Clinic",
        "lat": -25.65867313,
        "lon": 28.731590565
    },
    {
        "facility": "Dayanand Garden Home For The Aged",
        "lat": -29.919556389,
        "lon": 30.878011266
    },
    {
        "facility": "Daymed Clinic",
        "lat": -29.558866667,
        "lon": 30.409416667
    },
    {
        "facility": "Delareyville Hospital",
        "lat": -26.68825,
        "lon": 25.45735
    },
    {
        "facility": "Delft Day Hospital",
        "lat": -33.974027701,
        "lon": 18.641513069
    },
    {
        "facility": "DELMAS Medicross",
        "lat": -26.150555556,
        "lon": 28.68
    },
    {
        "facility": "Delmas Provincial Hospital",
        "lat": -26.151980778,
        "lon": 28.666620373
    },
    {
        "facility": "Denmar Clinic",
        "lat": -25.802777778,
        "lon": 28.299166667
    },
    {
        "facility": "Dihlabeng Streek Hospital",
        "lat": -28.233516667,
        "lon": 28.3155
    },
    {
        "facility": "Dikebu Hospital",
        "lat": -25.481095867,
        "lon": 28.094454777
    },
    {
        "facility": "Dilokong Hospital",
        "lat": -24.615,
        "lon": 30.171111111
    },
    {
        "facility": "Discoverers Memorial Hospital",
        "lat": -24.667316667,
        "lon": 30.329733333
    },
    {
        "facility": "Dithlare Step-Down",
        "lat": -25.75623385,
        "lon": 28.155266463
    },
    {
        "facility": "Donald Fraizer Hospital",
        "lat": -22.887278846,
        "lon": 30.479630866
    },
    {
        "facility": "Donald Gordon Hospital",
        "lat": -26.179483333,
        "lon": 28.034316667
    },
    {
        "facility": "Doone Village OAH",
        "lat": -29.7961369,
        "lon": 30.855390027
    },
    {
        "facility": "Dora Nginza Hospital",
        "lat": -33.879333333,
        "lon": 25.562633333
    },
    {
        "facility": "Dordrecht Hospital",
        "lat": -31.3757,
        "lon": 27.04715
    },
    {
        "facility": "Dr Machupe Mphahlele Memorial Hospital",
        "lat": -24.300033333,
        "lon": 29.249166667
    },
    {
        "facility": "Dr Stander Radiology",
        "lat": -23.90899519,
        "lon": 29.46331669
    },
    {
        "facility": "Drakenstein Private Hospital",
        "lat": -33.717216667,
        "lon": 18.96095
    },
    {
        "facility": "Driefontein Clinic",
        "lat": -28.34275,
        "lon": 29.6979
    },
    {
        "facility": "Duff Scott Hospital",
        "lat": -26.845466667,
        "lon": 26.760233333
    },
    {
        "facility": "Duiwelskloof Hospital",
        "lat": -23.692,
        "lon": 30.139016667
    },
    {
        "facility": "Dundee Provincial Hospital",
        "lat": -28.172447271,
        "lon": 30.229872348
    },
    {
        "facility": "Dunnottar Hospital",
        "lat": -26.352933333,
        "lon": 28.434816667
    },
    {
        "facility": "Dunstan Farrell SANTA Centre",
        "lat": -30.318719064,
        "lon": 30.653287353
    },
    {
        "facility": "Durban Chest Clinic",
        "lat": -29.856566667,
        "lon": 30.010266667
    },
    {
        "facility": "Durban Deep Hospital",
        "lat": -26.17205,
        "lon": 27.85765
    },
    {
        "facility": "Durban International Airport",
        "lat": -29.966919492,
        "lon": 30.946151759
    },
    {
        "facility": "Durban Oncology Clinic",
        "lat": -29.851036567,
        "lon": 30.986200583
    },
    {
        "facility": "East Griqua And Usher Memorial Hospital",
        "lat": -30.544283333,
        "lon": 29.41545
    },
    {
        "facility": "East London Medi Cross",
        "lat": -32.9927,
        "lon": 27.907083333
    },
    {
        "facility": "East Lynne Clinic",
        "lat": -25.705738901,
        "lon": 28.28431491
    },
    {
        "facility": "East Rand SANTA Centre",
        "lat": -26.173716667,
        "lon": 28.396633333
    },
    {
        "facility": "Eben Donges Hospital",
        "lat": -33.644516667,
        "lon": 19.466916667
    },
    {
        "facility": "Ebony Park Primary Health",
        "lat": -25.997269807,
        "lon": 28.182679866
    },
    {
        "facility": "Eden Gardens Hospital",
        "lat": -29.641520451,
        "lon": 30.358023800 
    },
    {
        "facility": "Edendale Hospital",
        "lat": -29.647222222,
        "lon": 30.331388889
    },
    {
        "facility": "Edenhof Old Age Home",
        "lat": -32.252460845,
        "lon": 24.531680698
    },
    {
        "facility": "Edenvale Day Clinic",
        "lat": -26.149444444,
        "lon": 28.157222222
    },
    {
        "facility": "Edenvale General Hospital",
        "lat": -26.128543487,
        "lon": 28.128913194
    },
    {
        "facility": "Edenvale Medi Cross",
        "lat": -26.134180409,
        "lon": 28.153801135
    },
    {
        "facility": "Eersterivier Hospital",
        "lat": -33.997378273,
        "lon": 18.719141778
    },
    {
        "facility": "Ekombe Mission Hospital",
        "lat": -28.633816667,
        "lon": 30.883883333
    },
    {
        "facility": "Ekuhlengeni Care Centre",
        "lat": -30.017716667,
        "lon": 30.922733333
    },
    {
        "facility": "Ekurhuleni Clinic",
        "lat": -26.192783333,
        "lon": 28.306683333
    },
    {
        "facility": "Ekurhuleni SurgiKlin",
        "lat": -26.1016531,
        "lon": 28.1631898
    },
    {
        "facility": "Elandsrand Med Station",
        "lat": -26.449615655,
        "lon": 27.362554276
    },
    {
        "facility": "Elardus Park Medi Cross",
        "lat": -25.828233333,
        "lon": 28.25825
    },
    {
        "facility": "Elim Clinic",
        "lat": -26.109166667,
        "lon": 28.2186
    },
    {
        "facility": "Elim Hospital",
        "lat": -23.152891424,
        "lon": 30.056723292
    },
    {
        "facility": "Elizabeth Donkin Hospital",
        "lat": -33.98585,
        "lon": 25.632966667
    },
    {
        "facility": "Elizabeth Ross Hospital",
        "lat": -28.584996768,
        "lon": 28.813787102
    },
    {
        "facility": "Elliot Hospital",
        "lat": -31.333533333,
        "lon": 27.849883333
    },
    {
        "facility": "Ellisras Hospital",
        "lat": -23.678333333,
        "lon": 27.701944444
    },
    {
        "facility": "Elphin Lodge",
        "lat": -26.129166667,
        "lon": 28.120277778
    },
    {
        "facility": "Elsie Ballot Hospital",
        "lat": -27.0085,
        "lon": 29.868233333
    },
    {
        "facility": "Elsies River Hospital",
        "lat": -33.93150118,
        "lon": 18.577227228
    },
    {
        "facility": "Emalahleni Hospital",
        "lat": -25.8748107,
        "lon": 29.215987066
    },
    {
        "facility": "Embekweni Hospital",
        "lat": -30.301583333,
        "lon": 27.082183333
    },
    {
        "facility": "Embhuleni Hospital",
        "lat": -26.048055556,
        "lon": 30.816111111
    },
    {
        "facility": "Emcare Clinic",
        "lat": -25.484722222,
        "lon": 30.950555556
    },
    {
        "facility": "Emmaus Hospital",
        "lat": -28.81245,
        "lon": 29.54095
    },
    {
        "facility": "Emmeson House",
        "lat": -29.817802266,
        "lon": 31.01823756
    },
    {
        "facility": "EmoyaMed",
        "lat": -29.06503139438072,
        "lon": 26.168284251402703
    },
    {
        "facility": "Empangeni Hospital",
        "lat": -28.743533333,
        "lon": 31.890683333
    },
    {
        "facility": "Empangeni Medi Cross",
        "lat": -28.739483333,
        "lon": 31.8952
    },
    {
        "facility": "Empilisweni Clinic",
        "lat": -26.709416667,
        "lon": 27.837966667
    },
    {
        "facility": "Empilisweni Hospital",
        "lat": -30.532376965,
        "lon": 27.374556852
    },
    {
        "facility": "Empilweni Hospital",
        "lat": -29.711583333,
        "lon": 30.6623
    },
    {
        "facility": "Empilweni Medical Centre",
        "lat": -26.335583333,
        "lon": 28.245466667
    },
    {
        "facility": "Entabeni Clinic",
        "lat": -30.970836693,
        "lon": 30.236811934
    },
    {
        "facility": "Entambeni Step-Down",
        "lat": -28.6018,
        "lon": 32.084316667
    },
    {
        "facility": "Ermelo Hospital",
        "lat": -26.234733333,
        "lon": 30.4697
    },
    {
        "facility": "Ernest Oppenheimer Hospital",
        "lat": -27.962716667,
        "lon": 26.77235
    },
    {
        "facility": "Ernest Oppenheimer intake",
        "lat": -26.956666667,
        "lon": 26.777777778
    },
    {
        "facility": "ESDA Frailcare",
        "lat": -26.242777777,
        "lon": 28.405833333
    },
    {
        "facility": "Eshowe Provincial Hospital",
        "lat": -28.893283333,
        "lon": 31.474766667
    },
    {
        "facility": "Estcourt Provincial Hospital",
        "lat": -29.01235,
        "lon": 29.863566667
    },
    {
        "facility": "Evander Hospital",
        "lat": -26.505833333,
        "lon": 29.130555556
    },
    {
        "facility": "Evander Offices",
        "lat": -26.469166667,
        "lon": 29.098055556
    },
    {
        "facility": "Evergreen Nursing Home",
        "lat": -25.878888889,
        "lon": 29.217777778
    },
    {
        "facility": "Ezibeleni Orthopaedic Home",
        "lat": -26.207698732,
        "lon": 28.158511174
    },
    {
        "facility": "F.H. Odendaal Hospital",
        "lat": -24.701401934,
        "lon": 28.422062828
    },
    {
        "facility": "Fairtrees Retirement Village",
        "lat": -33.829702129,
        "lon": 18.665162059
    },
    {
        "facility": "False Bay Hospital",
        "lat": -34.1309,
        "lon": 18.413816667
    },
    {
        "facility": "Far East Rand Hospital",
        "lat": -26.235166667,
        "lon": 28.403983333
    },
    {
        "facility": "Fauchard Clinic",
        "lat": -26.164766667,
        "lon": 27.917116667
    },
    {
        "facility": "Faure Hospital",
        "lat": -34.0299,
        "lon": 18.746266667
    },
    {
        "facility": "Ficksburg District Hospital",
        "lat": -28.868866667,
        "lon": 27.879633333
    },
    {
        "facility": "Flamwood Stepdown",
        "lat": -26.833605253,
        "lon": 26.683675625
    },
    {
        "facility": "Flemming House",
        "lat": -26.138866232,
        "lon": 28.089689038
    },
    {
        "facility": "Fochville Hospital",
        "lat": -26.481500421,
        "lon": 27.497372381
    },
    {
        "facility": "Fordsburg Clinic",
        "lat": -26.211933333,
        "lon": 28.0203
    },
    {
        "facility": "Fort Beaufort Hospital",
        "lat": -32.779283333,
        "lon": 26.623666667
    },
    {
        "facility": "Fort England Hospital",
        "lat": -33.316916667,
        "lon": 26.54375
    },
    {
        "facility": "Fort Napier Hospital",
        "lat": -29.613333333,
        "lon": 30.371
    },
    {
        "facility": "FOSA TB Settlement",
        "lat": -29.786716667,
        "lon": 30.968566667
    },
    {
        "facility": "Frankfort Provincial Hospital",
        "lat": -27.282066667,
        "lon": 28.494083333
    },
    {
        "facility": "Fraserburg Hospital",
        "lat": -31.91485,
        "lon": 21.51325
    },
    {
        "facility": "Free State Psychiatric Complex",
        "lat": -29.116733333,
        "lon": 26.220466667
    },
    {
        "facility": "Frere Hospital",
        "lat": -32.996016667,
        "lon": 27.893533333
    },
    {
        "facility": "Friendship Haven",
        "lat": -26.169166666,
        "lon": 27.704722222
    },
    {
        "facility": "Fritz Visser Hospital",
        "lat": -31.185533333,
        "lon": 24.95025
    },
    {
        "facility": "Frontier Hospital",
        "lat": -31.88995,
        "lon": 26.87255
    },
    {
        "facility": "G.F. Jooste Trauma Emergency Hospital",
        "lat": -33.98474955,
        "lon": 18.558329954
    },
    {
        "facility": "G.J. Crookes Hospital",
        "lat": -30.288583333,
        "lon": 30.74545
    },
    {
        "facility": "GALESHEWE DAY",
        "lat": -28.729140546,
        "lon": 24.746468673
    },
    {
        "facility": "Gamalakhe Clinic",
        "lat": -30.810555556,
        "lon": 30.333065455
    },
    {
        "facility": "Ganyesa Community Hospital",
        "lat": -26.59185,
        "lon": 24.170833333
    },
    {
        "facility": "Ga-Rankuwa Hospital",
        "lat": -25.618316598,
        "lon": 28.011718864
    },
    {
        "facility": "Gardens Medi Cross",
        "lat": -33.973966667,
        "lon": 25.531066667
    },
    {
        "facility": "Garies Provincial Hospital",
        "lat": -30.572716667,
        "lon": 17.985516667
    },
    {
        "facility": "Gateway Hospital",
        "lat": -29.723182064,
        "lon": 31.071852951
    },
    {
        "facility": "GATEWAY International",
        "lat": -23.857710891,
        "lon": 29.453665762
    },
    {
        "facility": "Geluksoord Tehuis",
        "lat": -33.94112393,
        "lon": 18.833332363
    },
    {
        "facility": "GEORGE Airport",
        "lat": -34.000456147,
        "lon": 22.381393871
    },
    {
        "facility": "George Masebe Hospital",
        "lat": -23.87508156,
        "lon": 28.694991477
    },
    {
        "facility": "George Mukhari Hospital",
        "lat": -25.617407298,
        "lon": 28.011504035
    },
    {
        "facility": "George Provincial Hospital",
        "lat": -33.951851734,
        "lon": 22.451101867
    },
    {
        "facility": "George Stegmann Community Hospital",
        "lat": -25.180555556,
        "lon": 27.161666667
    },
    {
        "facility": "Gerimed Langebaan",
        "lat": -33.089461682,
        "lon": 18.032479579
    },
    {
        "facility": "Germiston Medi Cross",
        "lat": -26.2521,
        "lon": 28.1879
    },
    {
        "facility": "Germiston Provincial Hospital",
        "lat": -26.220235705,
        "lon": 28.164368594
    },
    {
        "facility": "Gezina Medi Cross",
        "lat": -25.719883333,
        "lon": 28.2064
    },
    {
        "facility": "Glen Grey Hospital",
        "lat": -31.704183333,
        "lon": 27.231866667
    },
    {
        "facility": "Glynnview",
        "lat": -26.192783333,
        "lon": 28.306683333
    },
    {
        "facility": "Golden Harvest 2",
        "lat": -26.077832638,
        "lon": 27.963147952
    },
    {
        "facility": "Golden Harvest 3",
        "lat": -26.039424808,
        "lon": 27.584307776
    },
    {
        "facility": "Goldfields Regional Hospital",
        "lat": -27.963266667,
        "lon": 26.766083333
    },
    {
        "facility": "Goldfields West Hospital",
        "lat": -26.358316667,
        "lon": 27.60645
    },
    {
        "facility": "Gordonia Hospital",
        "lat": -28.450666667,
        "lon": 21.25865
    },
    {
        "facility": "Graceland Nursing Home",
        "lat": -29.879496078,
        "lon": 30.987981171
    },
    {
        "facility": "Grassy Park Clinic",
        "lat": -34.042166667,
        "lon": 18.5084
    },
    {
        "facility": "Green Pastures OAH",
        "lat": -33.81952926,
        "lon": 18.666283261
    },
    {
        "facility": "Greenacres Medi Cross",
        "lat": -33.949183333,
        "lon": 25.583933333
    },
    {
        "facility": "Grey Hospital",
        "lat": -32.999483333,
        "lon": 27.397933333
    },
    {
        "facility": "Greymont Medi Cross",
        "lat": -26.167816667,
        "lon": 27.96545
    },
    {
        "facility": "Grey's Hospital",
        "lat": -29.585066667,
        "lon": 30.3722
    },
    {
        "facility": "Greytown Hospital",
        "lat": -29.066894012,
        "lon": 30.600467424
    },
    {
        "facility": "Groblersdal Hospital",
        "lat": -25.176585915,
        "lon": 29.404007667
    },
    {
        "facility": "Groenkloof Healthcare Facility",
        "lat": -25.77162667,
        "lon": 28.210565124
    },
    {
        "facility": "Groote Schuur Hospital",
        "lat": -33.940922393,
        "lon": 18.463295256
    },
    {
        "facility": "Guguletu Clinic",
        "lat": -33.987466667,
        "lon": 18.5649
    },
    {
        "facility": "GVI Oncology",
        "lat": -33.947424084,
        "lon": 25.575651719
    },
    {
        "facility": "H.A. Grove Hospital",
        "lat": -25.687166667,
        "lon": 30.035633333
    },
    {
        "facility": "H.J.E. Schultz SANTA Centre",
        "lat": -25.847833333,
        "lon": 29.169683333
    },
    {
        "facility": "Hanover Park Clinic",
        "lat": -33.993931564,
        "lon": 18.527879644
    },
    {
        "facility": "Harrismith Thebe Hospital",
        "lat": -28.272333369,
        "lon": 29.136625278
    },
    {
        "facility": "Harry Comay SANTA Centre",
        "lat": -33.976638999,
        "lon": 22.472227963
    },
    {
        "facility": "Hartenbos OAH",
        "lat": -34.123274487,
        "lon": 22.113102023
    },
    {
        "facility": "Harvey Cohen Centre",
        "lat": -26.295866667,
        "lon": 27.90165
    },
    {
        "facility": "Hayfields Medi Cross",
        "lat": -29.62345,
        "lon": 30.408783333
    },
    {
        "facility": "Health Worx - Boksburg",
        "lat": -26.178816667,
        "lon": 28.254166667
    },
    {
        "facility": "Health Worx - Centurion",
        "lat": -25.8609,
        "lon": 28.187216667
    },
    {
        "facility": "Health Worx - Cresta",
        "lat": -26.131309661,
        "lon": 27.977381973
    },
    {
        "facility": "Health Worx - Midrand",
        "lat": -25.979652241,
        "lon": 28.117134459
    },
    {
        "facility": "Health Worx - Randridge",
        "lat": -26.097633333,
        "lon": 27.9422
    },
    {
        "facility": "Health Worx - Willowbridge",
        "lat": -33.868883333,
        "lon": 18.633216667
    },
    {
        "facility": "Heidelburg Hospital",
        "lat": -26.503033333,
        "lon": 28.351666667
    },
    {
        "facility": "Heideldal Clinic",
        "lat": -29.138157939,
        "lon": 26.246195594
    },
    {
        "facility": "Heideveld Clinic",
        "lat": -33.969,
        "lon": 18.546616667
    },
    {
        "facility": "Heilbron Provincial Hospital",
        "lat": -27.290740437,
        "lon": 27.961413306
    },
    {
        "facility": "Helen Bishop Orthopaedic Hospital",
        "lat": -28.723166667,
        "lon": 24.766716667
    },
    {
        "facility": "Helen Franz Hospital",
        "lat": -23.283271731,
        "lon": 29.113031359
    },
    {
        "facility": "Helen Joseph Hospital",
        "lat": -26.185116667,
        "lon": 27.991033333
    },
    {
        "facility": "Helen Keller Society",
        "lat": -33.944810488,
        "lon": 18.493369285
    },
    {
        "facility": "Helpmakaar Hospital",
        "lat": -28.848866667,
        "lon": 23.253966667
    },
    {
        "facility": "Henry Bernstein Chest Hospital",
        "lat": -26.680816667,
        "lon": 27.93625
    },
    {
        "facility": "Herfsmed",
        "lat": -25.728083333,
        "lon": 28.254616667
    },
    {
        "facility": "Heritage Manor Rehab",
        "lat": -34.128412646,
        "lon": 18.906771961
    },
    {
        "facility": "Hermanus Hospital",
        "lat": -34.422155583,
        "lon": 19.228112731
    },
    {
        "facility": "Hester Malan Hospital",
        "lat": -29.059922377,
        "lon": 23.767145803
    },
    {
        "facility": "Hewu Hospital",
        "lat": -32.169787571,
        "lon": 26.792233369
    },
    {
        "facility": "Hibiscus Private Hospital",
        "lat": -30.742516667,
        "lon": 30.451216667
    },
    {
        "facility": "Highlands House",
        "lat": -33.935033333,
        "lon": 18.418833333
    },
    {
        "facility": "Highway Hospice",
        "lat": -29.830475796,
        "lon": 30.97406232
    },
    {
        "facility": "Hillbrow Community Health Centre",
        "lat": -26.191066667,
        "lon": 28.045466667
    },
    {
        "facility": "Hillcrest (Private)",
        "lat": -29.783487876,
        "lon": 30.745853417
    },
    {
        "facility": "Hillcrest Medi Cross",
        "lat": -29.776716667,
        "lon": 30.763
    },
    {
        "facility": "Hillcrest Private Hospital",
        "lat": -29.790159654,
        "lon": 30.742505766
    },
    {
        "facility": "Hlabisa Hospital",
        "lat": -28.145733333,
        "lon": 31.877933333
    },
    {
        "facility": "Holiday Inn",
        "lat": -26.135058566,
        "lon": 28.227269307
    },
    {
        "facility": "Holy Cross Hospital",
        "lat": -31.080883333,
        "lon": 29.493616667
    },
    {
        "facility": "Hoopstad Provincial Hospital",
        "lat": -27.834216667,
        "lon": 25.907516667
    },
    {
        "facility": "Hope Convalescent Home & Hope Training Home",
        "lat": -26.172976841,
        "lon": 28.029639818
    },
    {
        "facility": "Hopelands Cancer Centre",
        "lat": -29.620397569,
        "lon": 30.37152481
    },
    {
        "facility": "Horizon Clinic",
        "lat": -26.218016667,
        "lon": 28.241
    },
    {
        "facility": "Hospice",
        "lat": -33.891117263,
        "lon": 18.571510539
    },
    {
        "facility": "Hospice Assoc Of Wits",
        "lat": -26.241111111,
        "lon": 27.946111111
    },
    {
        "facility": "Hospice Assoc Of Wits 1",
        "lat": -26.167777778,
        "lon": 28.069444444
    },
    {
        "facility": "Hospice Assoc Of Wits 2",
        "lat": -26.241111111,
        "lon": 27.946111111
    },
    {
        "facility": "Hospitaalpark Clinic (Pty) Ltd.",
        "lat": -29.138033333,
        "lon": 26.198483333
    },
    {
        "facility": "Hottentots Holland Hospital",
        "lat": -34.075871632,
        "lon": 18.856570689
    },
    {
        "facility": "Houghton Hospice",
        "lat": -26.167770271,
        "lon": 28.074531952
    },
    {
        "facility": "House Theodore OAH",
        "lat": -26.075,
        "lon": 27.77
    },
    {
        "facility": "Hout Bay Eye Clinic",
        "lat": -34.031266667,
        "lon": 18.348016667
    },
    {
        "facility": "Huis Ander V/d Walt",
        "lat": -33.90466473,
        "lon": 18.627730919
    },
    {
        "facility": "Huis De Kuilen",
        "lat": -33.922982995,
        "lon": 18.682139315
    },
    {
        "facility": "Huis Hoeveld",
        "lat": -26.165944498,
        "lon": 27.978983436
    },
    {
        "facility": "Huis Seata OAH",
        "lat": -26.702127991,
        "lon": 27.834156
    },
    {
        "facility": "Huis Uike Laan",
        "lat": -26.708888889,
        "lon": 27.093055556
    },
    {
        "facility": "Huis Ysterplaat OAH",
        "lat": -33.913888889,
        "lon": 18.482777778
    },
    {
        "facility": "Humansdorp Hospital",
        "lat": -34.029809658,
        "lon": 24.780779733
    },
    {
        "facility": "ICC Medical Centre",
        "lat": -29.855833333,
        "lon": 31.029722222
    },
    {
        "facility": "Ikwezi Neuro Clinic",
        "lat": -32.9987,
        "lon": 27.89895
    },
    {
        "facility": "Impala Hospital",
        "lat": -25.533930835,
        "lon": 27.201378629
    },
    {
        "facility": "Impungwe Clinic",
        "lat": -25.9056,
        "lon": 29.133266667
    },
    {
        "facility": "Indwe Hospital",
        "lat": -31.468533333,
        "lon": 27.33865
    },
    {
        "facility": "Intercare (Centurion)",
        "lat": -25.885555556,
        "lon": 28.206666667
    },
    {
        "facility": "Intercare Blaauwberg",
        "lat": -33.815511126,
        "lon": 18.493722035
    },
    {
        "facility": "Intercare Medical Centre",
        "lat": -33.815312837,
        "lon": 18.493643864
    },
    {
        "facility": "Intercare Sub- Acute",
        "lat": -33.944529728,
        "lon": 22.476136314
    },
    {
        "facility": "Intercare Sub-Acute (Glen)",
        "lat": -25.765558583,
        "lon": 28.280517867
    },
    {
        "facility": "Intercare Sub-Acute (Hazel)",
        "lat": -25.783888889,
        "lon": 28.360833333
    },
    {
        "facility": "Intercare Sub-Acute (Silver)",
        "lat": -25.783888889,
        "lon": 28.360833333
    },
    {
        "facility": "Intercare SubAcute Bellville",
        "lat": -33.872222222,
        "lon": 18.636944444
    },
    {
        "facility": "Intercare sub-acute Clinic",
        "lat": -33.87200589,
        "lon": 18.637061898
    },
    {
        "facility": "Isilimela Hospital",
        "lat": -31.620916667,
        "lon": 29.544133333
    },
    {
        "facility": "Isithebe Medical Centre",
        "lat": -29.100316667,
        "lon": 31.416783333
    },
    {
        "facility": "Itemoheng District Hospital",
        "lat": -28.326501,
        "lon": 27.612655
    },
    {
        "facility": "Itshelejuba Hospital",
        "lat": -27.276790789,
        "lon": 31.34759424
    },
    {
        "facility": "J.D. Verster Hospital",
        "lat": -25.862216667,
        "lon": 26.896383333
    },
    {
        "facility": "Jabulani Hospital",
        "lat": -26.248206997,
        "lon": 27.857575028
    },
    {
        "facility": "Jakaranda Haven",
        "lat": -25.766515623,
        "lon": 28.222544158
    },
    {
        "facility": "Jamestown Hospital",
        "lat": -31.121816667,
        "lon": 26.8068
    },
    {
        "facility": "Jan Kemp Provincial Hospital",
        "lat": -27.917866667,
        "lon": 24.837083333
    },
    {
        "facility": "Jan Richter Centre",
        "lat": -29.625064851,
        "lon": 30.38141632
    },
    {
        "facility": "Jane Furse Hospital",
        "lat": -24.73,
        "lon": 29.858055556
    },
    {
        "facility": "Jane Keyser Clinic",
        "lat": -27.751633333,
        "lon": 24.805133333
    },
    {
        "facility": "Jeppe's Reef Border Post",
        "lat": -25.750395605,
        "lon": 31.468470519
    },
    {
        "facility": "Job  F  Tabane Hospital",
        "lat": -25.677324403,
        "lon": 27.245476267
    },
    {
        "facility": "Johan Heyns Community H",
        "lat": -26.702222222,
        "lon": 27.833611111
    },
    {
        "facility": "Johannesburg Eye Hospital",
        "lat": -26.133883333,
        "lon": 27.974116667
    },
    {
        "facility": "John D Newberry Hospital",
        "lat": -28.910209475,
        "lon": 27.574263713
    },
    {
        "facility": "Jose Pearson SANTA Centre",
        "lat": -33.882050798,
        "lon": 25.484976715
    },
    {
        "facility": "Jubilee Community Hospital",
        "lat": -25.40290694,
        "lon": 28.264928058
    },
    {
        "facility": "Kakamas Hospital",
        "lat": -28.774583333,
        "lon": 20.617333333
    },
    {
        "facility": "Kalafong Hospital",
        "lat": -25.761616667,
        "lon": 28.091366667
    },
    {
        "facility": "Kamhalushwa Clinic",
        "lat": -25.665072727,
        "lon": 31.680601192
    },
    {
        "facility": "KANYAMAZANE CLINIC",
        "lat": -25.465555556,
        "lon": 31.183888889
    },
    {
        "facility": "Karl Bremmer Hospital",
        "lat": -33.892056286,
        "lon": 18.608764017
    },
    {
        "facility": "Katleho Hospital",
        "lat": -28.111666666,
        "lon": 26.859166666
    },
    {
        "facility": "Keimoes Hospital",
        "lat": -28.70585,
        "lon": 20.966766667
    },
    {
        "facility": "Kembirch Medi Cross",
        "lat": -26.068416667,
        "lon": 28.230016667
    },
    {
        "facility": "Kenhardt Community Hospital",
        "lat": -29.349883333,
        "lon": 21.153933333
    },
    {
        "facility": "Kenilworth Clinic",
        "lat": -33.995094035,
        "lon": 18.472491887
    },
    {
        "facility": "Kgapane Hospital",
        "lat": -23.651418795,
        "lon": 30.216817061
    },
    {
        "facility": "Khayalami Hospital",
        "lat": -26.071666667,
        "lon": 28.217016667
    },
    {
        "facility": "Khayelitsha Day Clinic",
        "lat": -34.027972142,
        "lon": 18.664832664
    },
    {
        "facility": "Khayelitsha District Hospital",
        "lat": -34.051388889,
        "lon": 18.676111111
    },
    {
        "facility": "Khuveni Maternity Hospital",
        "lat": -26.139833333,
        "lon": 28.4232
    },
    {
        "facility": "Kiaat Private Hospital",
        "lat": -25.403333333,
        "lon": 30.983611111
    },
    {
        "facility": "Kids Clinic",
        "lat": -33.961233201,
        "lon": 18.376011682
    },
    {
        "facility": "Kilner Park Narco Clinic",
        "lat": -25.711283333,
        "lon": 28.261083333
    },
    {
        "facility": "Kimberley Hospital",
        "lat": -28.746332575,
        "lon": 24.770904314
    },
    {
        "facility": "Kimberley International",
        "lat": -28.794444444,
        "lon": 24.772222222
    },
    {
        "facility": "Kimberley Medi Cross",
        "lat": -28.743583333,
        "lon": 24.757183333
    },
    {
        "facility": "Kimberley Psychiatric Hospital",
        "lat": -28.775,
        "lon": 24.722777778
    },
    {
        "facility": "King Edward VIII Hospital",
        "lat": -29.880066667,
        "lon": 30.989616667
    },
    {
        "facility": "King George V Hospital",
        "lat": -29.822433333,
        "lon": 30.985966667
    },
    {
        "facility": "King Shaka Airport",
        "lat": -29.609244569,
        "lon": 31.118440377
    },
    {
        "facility": "Kingsley Medical Centre",
        "lat": -25.74505,
        "lon": 28.204083333
    },
    {
        "facility": "Kirkwood Chest Hospital",
        "lat": -33.3999,
        "lon": 25.443366667
    },
    {
        "facility": "KLARADYN OAH",
        "lat": -33.885986738,
        "lon": 18.689095138
    },
    {
        "facility": "Klerksdorp Hospital",
        "lat": -26.878483644,
        "lon": 26.663535496
    },
    {
        "facility": "Knights Chest Hospital",
        "lat": -26.19485,
        "lon": 28.178716667
    },
    {
        "facility": "Knobel Hospital",
        "lat": -23.630870748,
        "lon": 29.121498386
    },
    {
        "facility": "KOA Pienaar OAH",
        "lat": -26.2677,
        "lon": 28.2254
    },
    {
        "facility": "Koekania retirement village",
        "lat": -24.686128799,
        "lon": 28.38053246
    },
    {
        "facility": "Kokstad Provincial Hospital",
        "lat": -30.544133333,
        "lon": 29.41555
    },
    {
        "facility": "Komga Hospital",
        "lat": -32.579333333,
        "lon": 27.90055
    },
    {
        "facility": "Kopano MDR Clinic",
        "lat": -27.980541849,
        "lon": 26.694657956
    },
    {
        "facility": "Kopanong Hospital",
        "lat": -26.6387,
        "lon": 27.930916667
    },
    {
        "facility": "Kraaifontein Day Hospital",
        "lat": -33.853251892,
        "lon": 18.721707306
    },
    {
        "facility": "Kriel Medical Centre",
        "lat": -26.257166667,
        "lon": 29.265483333
    },
    {
        "facility": "Kroonstad Hospital",
        "lat": -27.6547,
        "lon": 27.236233333
    },
    {
        "facility": "Kruger Mpumalanga Int",
        "lat": -25.383907232,
        "lon": 31.098099802
    },
    {
        "facility": "Krugersdoorp Airfield( MP )",
        "lat": -26.079718584,
        "lon": 27.731500108
    },
    {
        "facility": "Krugersdorp Medi Cross",
        "lat": -26.106866667,
        "lon": 27.778
    },
    {
        "facility": "Kukhanya Ret. Village",
        "lat": -24.556944444,
        "lon": 28.302222222
    },
    {
        "facility": "Kungweni Welfare",
        "lat": -25.778888888,
        "lon": 28.343611111
    },
    {
        "facility": "Kuruman Hospital",
        "lat": -27.4684,
        "lon": 23.428716667
    },
    {
        "facility": "Kutlwanong Hospital",
        "lat": -27.877377178,
        "lon": 26.658068401
    },
    {
        "facility": "Kwa Magwaza Hospital",
        "lat": -28.629199637,
        "lon": 31.340974355
    },
    {
        "facility": "Kwa Mashu Polyclinic",
        "lat": -29.742933333,
        "lon": 30.97195
    },
    {
        "facility": "KwaCeza Hospital",
        "lat": -27.996845134,
        "lon": 31.376929279
    },
    {
        "facility": "Kwamhlanga Hospital",
        "lat": -25.417266667,
        "lon": 28.70305
    },
    {
        "facility": "KwaMsane Clinic",
        "lat": -28.427957979,
        "lon": 32.148099675
    },
    {
        "facility": "KwaNdengezi Clinc",
        "lat": -29.850066667,
        "lon": 30.766766667
    },
    {
        "facility": "Kwa-Thema Medicross",
        "lat": -26.297750607,
        "lon": 28.391261398
    },
    {
        "facility": "La Clemence",
        "lat": -33.974995599,
        "lon": 18.842042521
    },
    {
        "facility": "La Gratitude Old Age Home",
        "lat": -33.822176759,
        "lon": 18.650452144
    },
    {
        "facility": "La Verna Clinic",
        "lat": -28.554933333,
        "lon": 29.7799
    },
    {
        "facility": "La Vie Est Belle Oah",
        "lat": -33.840933333,
        "lon": 18.671366667
    },
    {
        "facility": "Ladismith Hospital",
        "lat": -33.486524925,
        "lon": 21.268676998
    },
    {
        "facility": "Lady Grey Hospital",
        "lat": -30.712033333,
        "lon": 27.2174
    },
    {
        "facility": "Ladybrand Provincial Hospital",
        "lat": -29.191866667,
        "lon": 27.457533333
    },
    {
        "facility": "Laingsburg Hospital",
        "lat": -33.195166667,
        "lon": 20.857983333
    },
    {
        "facility": "Lambrecht Hospital",
        "lat": -33.95728761,
        "lon": 22.456438394
    },
    {
        "facility": "Langeberg Medi Cross",
        "lat": -33.839083333,
        "lon": 18.705
    },
    {
        "facility": "Lanseria Airport",
        "lat": -25.935,
        "lon": 27.925
    },
    {
        "facility": "Larmenier Frailcare",
        "lat": -33.936111111,
        "lon": 18.425555556
    },
    {
        "facility": "Leatitchia Bam Day Hosp",
        "lat": -33.807523281,
        "lon": 25.398200551
    },
    {
        "facility": "LEBOMBO BORDER",
        "lat": -25.443333333,
        "lon": 31.986666667
    },
    {
        "facility": "Lebowakgomo Provincial Hospital",
        "lat": -24.293819518,
        "lon": 29.529270772
    },
    {
        "facility": "Leeuwendal Mediclinic",
        "lat": -33.933466667,
        "lon": 18.405266667
    },
    {
        "facility": "Lehurutshe Hospital",
        "lat": -25.47657521,
        "lon": 25.982361753
    },
    {
        "facility": "Lendmed Randfontein Private Hospital",
        "lat": -26.167366667,
        "lon": 27.719166667
    },
    {
        "facility": "Lenmed Ahmed Kathrada",
        "lat": -26.32697828,
        "lon": 27.863079077
    },
    {
        "facility": "Lenmed Bokamosa Private Hospital",
        "lat": -24.579995832,
        "lon": 25.836485814
    },
    {
        "facility": "Lenmed Clinic",
        "lat": -26.327644573,
        "lon": 27.86328014
    },
    {
        "facility": "Lenmed Daxina Private Hospital",
        "lat": -26.385043125,
        "lon": 27.841616252
    },
    {
        "facility": "Lenmed Ethekwini Hospital and Heart Centre",
        "lat": -29.777083333,
        "lon": 30.995516667
    },
    {
        "facility": "Lenmed Howick Private Hospital",
        "lat": -29.476735451,
        "lon": 30.218909318
    },
    {
        "facility": "Lenmed Kathu Private Hospital",
        "lat": -27.699059829,
        "lon": 23.050956963
    },
    {
        "facility": "Lenmed La Verna Private Hospital",
        "lat": -28.555272359,
        "lon": 29.779852168
    },
    {
        "facility": "Lenmed Maputo Private Hospital",
        "lat": -25.944038718,
        "lon": 32.611836554
    },
    {
        "facility": "Lenmed Royal Hospital And Heart Centre",
        "lat": 28.7567,
        "lon": 24.7621
    },
    {
        "facility": "Lenmed Shifa Private Hospital",
        "lat": -29.832620343,
        "lon": 30.984417463
    },
    {
        "facility": "Lenmed Zamokuhle Private Hospital",
        "lat": -25.98357291,
        "lon": 28.23942261
    },
    {
        "facility": "Lentegeur Hospital",
        "lat": -34.026433333,
        "lon": 18.615066667
    },
    {
        "facility": "Lephalale Hospital",
        "lat": -23.678704419,
        "lon": 27.702800452
    },
    {
        "facility": "Leratong Hospital",
        "lat": -26.171994365,
        "lon": 27.806259569
    },
    {
        "facility": "Lesedi Clinic",
        "lat": -26.258055556,
        "lon": 27.935277778
    },
    {
        "facility": "Leslie Williams Memorial Hospital",
        "lat": -26.401083333,
        "lon": 27.4221
    },
    {
        "facility": "Letaba Hospital",
        "lat": -23.873452049,
        "lon": 30.268673566
    },
    {
        "facility": "Libertas Medical Centre",
        "lat": -33.913116667,
        "lon": 18.541183333
    },
    {
        "facility": "Lichtenburg Clinic",
        "lat": -24.7388,
        "lon": 26.403966667
    },
    {
        "facility": "Life Anncron Clinic",
        "lat": -26.840533333,
        "lon": 26.667716667
    },
    {
        "facility": "Life Bay View Hospital",
        "lat": -34.181347415,
        "lon": 22.131044275
    },
    {
        "facility": "Life Beacon Bay Hosp",
        "lat": -32.949085395,
        "lon": 27.939231957
    },
    {
        "facility": "Life Bedford Gardens Private Hospital",
        "lat": -26.190265231,
        "lon": 28.120758823
    },
    {
        "facility": "Life Benmed Park Clinic",
        "lat": -26.184461604,
        "lon": 28.312233163
    },
    {
        "facility": "Life Birchmed Day Clinic",
        "lat": -26.067566667,
        "lon": 28.218583333
    },
    {
        "facility": "Life Brenthurst Clinic",
        "lat": -26.183316667,
        "lon": 28.047333333
    },
    {
        "facility": "Life Brooklyn Chest Hospital",
        "lat": -33.901666667,
        "lon": 18.4846
    },
    {
        "facility": "Life Carstenhof Clinic",
        "lat": -26.028833333,
        "lon": 28.1406
    },
    {
        "facility": "Life Chatsmed Garden Hospital",
        "lat": -29.909066667,
        "lon": 30.907533333
    },
    {
        "facility": "Life Claremont Hospital",
        "lat": -33.986181455,
        "lon": 18.466962921
    },
    {
        "facility": "Life Cosmos Hospital",
        "lat": -25.884121939,
        "lon": 29.233172854
    },
    {
        "facility": "Life Crompton Hospital",
        "lat": -29.81045,
        "lon": 30.864016667
    },
    {
        "facility": "Life Daylene Clinic",
        "lat": -26.87715,
        "lon": 26.66475
    },
    {
        "facility": "Life Durdoc Clinic",
        "lat": -29.85945,
        "lon": 31.0171
    },
    {
        "facility": "Life East London Private Hospital",
        "lat": -33.013183333,
        "lon": 27.900333333
    },
    {
        "facility": "Life Empangeni Garden Clinic",
        "lat": -28.735826059,
        "lon": 31.893278826
    },
    {
        "facility": "Life Entabeni Hospital",
        "lat": -29.854683333,
        "lon": 30.986883333
    },
    {
        "facility": "Life Eugene Marais Hospital",
        "lat": -25.709687399,
        "lon": 28.194505445
    },
    {
        "facility": "Life Faerie Glen Hospital",
        "lat": -25.783335301,
        "lon": 28.290771789
    },
    {
        "facility": "Life Flora Clinic",
        "lat": 26.1517,
        "lon": 27.9199
    },
    {
        "facility": "Life Fourways Hospital",
        "lat": 26.0122,
        "lon": 27.9941
    },
    {
        "facility": "Life Glynnwood",
        "lat": -26.194766667,
        "lon": 28.308316667
    },
    {
        "facility": "Life Grey Monument Private Clinic",
        "lat": -32.880116667,
        "lon": 27.394233333
    },
    {
        "facility": "Life Groenkloof / Little Company of Mary",
        "lat": -25.77116674,
        "lon": 28.21643188
    },
    {
        "facility": "Life Hillcrest Hospital",
        "lat": -29.789783333,
        "lon": 30.759483333
    },
    {
        "facility": "Life Hilton Hospital",
        "lat": -29.5400516,
        "lon": 30.2992684
    },
    {
        "facility": "Life Hunterscraig (Psychiatric Clinic)",
        "lat": -33.968902428,
        "lon": 25.609894671
    },
    {
        "facility": "Life Isipingo Hospital",
        "lat": -29.987,
        "lon": 30.926833333
    },
    {
        "facility": "Life Isivivana Hospital",
        "lat": -34.02735,
        "lon": 24.7697
    },
    {
        "facility": "Life Kingsbury Hospital",
        "lat": -33.986187211,
        "lon": 18.468847646
    },
    {
        "facility": "Life Knysna Private Hospital",
        "lat": -34.052212817,
        "lon": 23.078876699
    },
    {
        "facility": "Life La Femme Clinic",
        "lat": -25.672222222,
        "lon": 27.238333333
    },
    {
        "facility": "Life Legae Private Clinic",
        "lat": -25.525555556,
        "lon": 28.0375
    },
    {
        "facility": "Life Maxwell Clinic",
        "lat": -29.850916667,
        "lon": 31.0162
    },
    {
        "facility": "Life Medgate Sameday Surgical Centre",
        "lat": -26.138816667,
        "lon": 27.86825
    },
    {
        "facility": "Life Mercantile Hospital",
        "lat": -33.936046063,
        "lon": 25.582587407
    },
    {
        "facility": "Life Midlands Medical Centre",
        "lat": -29.591783333,
        "lon": 30.3912
    },
    {
        "facility": "Life Midmed Hospital",
        "lat": -25.762941906,
        "lon": 29.458114752
    },
    {
        "facility": "Life Mount Edgecombe Hospital",
        "lat": -29.715867034,
        "lon": 31.035918515
    },
    {
        "facility": "Life New Kensington Clinic",
        "lat": -26.199783333,
        "lon": 28.076866667
    },
    {
        "facility": "Life Palm Sameday Surgical Centre",
        "lat": -25.876433333,
        "lon": 29.225883333
    },
    {
        "facility": "Life Pasteur Hospital",
        "lat": -29.134633333,
        "lon": 26.192683333
    },
    {
        "facility": "Life Peglerae Hospital",
        "lat": -25.675006197,
        "lon": 27.242494518
    },
    {
        "facility": "Life Piet Retief",
        "lat": -27.019968678,
        "lon": 30.805520308
    },
    {
        "facility": "Life Poortview Clinic",
        "lat": -26.090335714,
        "lon": 27.853493217
    },
    {
        "facility": "Life Pretoria North Surgical Centre",
        "lat": -25.671983333,
        "lon": 28.171416667
    },
    {
        "facility": "Life Queenstown Private Hospital",
        "lat": -31.898269232,
        "lon": 26.87971454
    },
    {
        "facility": "Life Riverfield Lodge",
        "lat": -25.94510469,
        "lon": 27.962373043
    },
    {
        "facility": "Life Robinson Private Hospital",
        "lat": -26.160277778,
        "lon": 27.709444444
    },
    {
        "facility": "Life Roseacres Clinic",
        "lat": -26.178090504,
        "lon": 28.169666192
    },
    {
        "facility": "Life Rosepark Hospital",
        "lat": -29.148233333,
        "lon": 26.178683333
    },
    {
        "facility": "Life Sandton Surgical Centre",
        "lat": -28.057966667,
        "lon": 28.057683333
    },
    {
        "facility": "Life Springs Parkland Clinic",
        "lat": -26.265633333,
        "lon": 28.435966667
    },
    {
        "facility": "Life St. Dominic's Hospital",
        "lat": -32.998666667,
        "lon": 27.902666667
    },
    {
        "facility": "Life St. George's Hospital",
        "lat": -33.968116611,
        "lon": 25.605074084
    },
    {
        "facility": "Life St. James Day Hospital",
        "lat": -33.000733333,
        "lon": 27.901333333
    },
    {
        "facility": "Life St. Joseph's Psychiatric Hospital",
        "lat": -29.855302624,
        "lon": 30.988251799
    },
    {
        "facility": "Life St. Marks Clinic East London",
        "lat": -32.9975,
        "lon": 27.90575
    },
    {
        "facility": "Life St. Mary's Private Hospital",
        "lat": -31.592733333,
        "lon": 28.754333333
    },
    {
        "facility": "Life St. Mary's Women's Clinic",
        "lat": -26.25905,
        "lon": 28.434966667
    },
    {
        "facility": "Life Suikerbosrand Clinic",
        "lat": -26.5004,
        "lon": 28.353616667
    },
    {
        "facility": "Life Vincent Pallotti Hospital",
        "lat": -33.943658648,
        "lon": 18.490424584
    },
    {
        "facility": "Life West Coast Private Hospital",
        "lat": -32.911826967,
        "lon": 17.990212171
    },
    {
        "facility": "Life Westville Hospital",
        "lat": -29.851237394,
        "lon": 30.931568902
    },
    {
        "facility": "Life Wilgeheuwel Hospital",
        "lat": -26.099621078,
        "lon": 27.924293695
    },
    {
        "facility": "Life Wilgers Hospital",
        "lat": -25.767466667,
        "lon": 28.3201
    },
    {
        "facility": "Lifemed Clinic",
        "lat": -26.1951,
        "lon": 27.9242
    },
    {
        "facility": "Lillian Ngoyi Clinic",
        "lat": -26.260555556,
        "lon": 27.9475
    },
    {
        "facility": "Lilly Kirshman OAH",
        "lat": -32.989960437,
        "lon": 27.91821132
    },
    {
        "facility": "Lime Acres Hospital",
        "lat": -28.35825,
        "lon": 23.518816667
    },
    {
        "facility": "Limpopo Oncology Clinic",
        "lat": -23.90356889,
        "lon": 29.46252534
    },
    {
        "facility": "Limpopo Provincial Hospital",
        "lat": -23.896507329,
        "lon": 29.457395266
    },
    {
        "facility": "Lister Clinic",
        "lat": -26.1995,
        "lon": 28.046516667
    },
    {
        "facility": "Little Eden",
        "lat": -26.131552345,
        "lon": 28.178912581
    },
    {
        "facility": "Little Eden home for Mentally Handicapped",
        "lat": -26.1315,
        "lon": 28.178816667
    },
    {
        "facility": "Livingstone Hospital",
        "lat": -33.925049253,
        "lon": 25.570070609
    },
    {
        "facility": "Lodge Care Centre, The",
        "lat": -33.018583333,
        "lon": 27.893766667
    },
    {
        "facility": "Loeriesfontein Hospital",
        "lat": -30.95355,
        "lon": 19.444483333
    },
    {
        "facility": "Lohatlha Military Hosp",
        "lat": -20.028888889,
        "lon": 23.108611111
    },
    {
        "facility": "Lonmin EPL clinic",
        "lat": -25.684070156,
        "lon": 27.612710456
    },
    {
        "facility": "Lonmin Karee Clinic",
        "lat": -25.698055556,
        "lon": 27.448888889
    },
    {
        "facility": "Lotus River Day Hospital",
        "lat": -34.029433333,
        "lon": 18.50725
    },
    {
        "facility": "Louis Pasteur Hospital",
        "lat": -25.748660258,
        "lon": 28.196082494
    },
    {
        "facility": "Louis Trichardt Hospital",
        "lat": -23.059033333,
        "lon": 29.909666667
    },
    {
        "facility": "Lower Umfolozi War Memorial Hospital",
        "lat": -28.74285,
        "lon": 31.890716667
    },
    {
        "facility": "Lowveld Day Hospital",
        "lat": -25.4756,
        "lon": 30.9776
    },
    {
        "facility": "Lusikisiki Hospital",
        "lat": -31.371944444,
        "lon": 29.573611111
    },
    {
        "facility": "Lydenburg Medical Center",
        "lat": -25.093223339,
        "lon": 30.45650197
    },
    {
        "facility": "Lydenburg Provincial Hospital",
        "lat": -25.109341687,
        "lon": 30.452499602
    },
    {
        "facility": "Lynmed Clinic",
        "lat": -25.7687,
        "lon": 28.269783333
    },
    {
        "facility": "Macadamia OAH",
        "lat": -23.867777778,
        "lon": 29.4975
    },
    {
        "facility": "Macadamia Village",
        "lat": -25.327603518,
        "lon": 31.002652331
    },
    {
        "facility": "Macassar Day Hospital",
        "lat": -34.059933333,
        "lon": 18.770166667
    },
    {
        "facility": "Maclear General Hospital",
        "lat": -31.0706,
        "lon": 28.348633333
    },
    {
        "facility": "Madadeni Hospital",
        "lat": -27.763094252,
        "lon": 30.051141753
    },
    {
        "facility": "Madwaleni Hospital",
        "lat": -31.962866667,
        "lon": 28.68055
    },
    {
        "facility": "Madzikane Kazulu Hospital",
        "lat": -30.868611111,
        "lon": 29.010555556
    },
    {
        "facility": "Magatle Hospital",
        "lat": -24.469166667,
        "lon": 29.413611111
    },
    {
        "facility": "Mahatma Gandhi Hospital",
        "lat": -29.718020846,
        "lon": 31.028955861
    },
    {
        "facility": "Maitland Clinic",
        "lat": -33.920916667,
        "lon": 18.489383333
    },
    {
        "facility": "Makapanstad Clinic",
        "lat": -25.230555556,
        "lon": 28.125277778
    },
    {
        "facility": "Malamulele Hospital",
        "lat": -23.0037,
        "lon": 30.69035
    },
    {
        "facility": "Mamelodi Day Hospital",
        "lat": -25.717883333,
        "lon": 28.367616667
    },
    {
        "facility": "Manapo Hospital",
        "lat": -28.51995,
        "lon": 28.823866667
    },
    {
        "facility": "Manguzi Hospital",
        "lat": -26.984225114,
        "lon": 32.754555784
    },
    {
        "facility": "Mangweni Clinic",
        "lat": -25.740833333,
        "lon": 31.818055556
    },
    {
        "facility": "Mankweng Hospital",
        "lat": -23.879621522,
        "lon": 29.726081974
    },
    {
        "facility": "Manne Dipico Hospital",
        "lat": -30.711283983,
        "lon": 25.113381902
    },
    {
        "facility": "Maphutha L Malatji Hospital",
        "lat": -23.926317758,
        "lon": 31.038974681
    },
    {
        "facility": "Mapulaneng Hospital",
        "lat": -24.844444444,
        "lon": 31.055277778
    },
    {
        "facility": "Margery Parkes SANTA Centre",
        "lat": -32.257733333,
        "lon": 24.530066667
    },
    {
        "facility": "Mar-Peh Medi-Care",
        "lat": -26.949883333,
        "lon": 29.2487
    },
    {
        "facility": "Martje Venter Provincial Hospital",
        "lat": -32.007833333,
        "lon": 26.258133333
    },
    {
        "facility": "Marula Platinum Clilnic",
        "lat": -24.501683333,
        "lon": 30.0696
    },
    {
        "facility": "Marydale Primary Heath Care",
        "lat": -29.4071,
        "lon": 22.105183333
    },
    {
        "facility": "Maseru Private Hospital",
        "lat": -29.349166667,
        "lon": 27.449722222
    },
    {
        "facility": "Matatiele Hospital",
        "lat": -30.34075,
        "lon": 28.800983333
    },
    {
        "facility": "Matibidi Hospital",
        "lat": -24.588055556,
        "lon": 30.769444444
    },
    {
        "facility": "Matikwane Hospital",
        "lat": -24.985607439,
        "lon": 31.236065148
    },
    {
        "facility": "Matlala Hospital",
        "lat": -24.8325,
        "lon": 29.501666667
    },
    {
        "facility": "Matsulu clinic",
        "lat": -25.517408827,
        "lon": 31.352489235
    },
    {
        "facility": "Mayo Clinic",
        "lat": -26.150429794,
        "lon": 27.920843728
    },
    {
        "facility": "Mbango Garden Clinic",
        "lat": -30.74895,
        "lon": 30.449383333
    },
    {
        "facility": "McCord Hospital",
        "lat": -29.837466667,
        "lon": 30.996883333
    },
    {
        "facility": "Mecklenburg Hospital",
        "lat": -24.386666667,
        "lon": 30.074444444
    },
    {
        "facility": "Medcare Clinic",
        "lat": -25.78985,
        "lon": 28.2476
    },
    {
        "facility": "Medco Highveld Radiology",
        "lat": -25.895191433,
        "lon": 29.226539096
    },
    {
        "facility": "Medfem Clinic",
        "lat": -26.07875,
        "lon": 28.011983333
    },
    {
        "facility": "Medical Forum Theatre (Day Hospital)",
        "lat": -33.9397,
        "lon": 25.595633333
    },
    {
        "facility": "Medicare Potchefstroom",
        "lat": -26.710666667,
        "lon": 27.094666667
    },
    {
        "facility": "Medicare Rustenburg",
        "lat": -25.685660521,
        "lon": 27.22535276
    },
    {
        "facility": "Medi-Clinic Barberton",
        "lat": -25.762148231,
        "lon": 31.051641511
    },
    {
        "facility": "Medi-Clinic Bloemfontein",
        "lat": -29.108648347,
        "lon": 26.203097384
    },
    {
        "facility": "Medi-Clinic Brits",
        "lat": -25.633055556,
        "lon": 27.783611111
    },
    {
        "facility": "Medi-Clinic Cape Gate",
        "lat": -33.848335632,
        "lon": 18.696453386
    },
    {
        "facility": "Medi-Clinic Cape Town",
        "lat": -33.936340559,
        "lon": 18.410534044
    },
    {
        "facility": "Medi-Clinic Constantiaberg",
        "lat": -34.026388133,
        "lon": 18.461090278
    },
    {
        "facility": "Medi-Clinic Durbanville",
        "lat": -33.825555556,
        "lon": 18.655
    },
    {
        "facility": "Medi-Clinic Emfuleni",
        "lat": -26.704066667,
        "lon": 27.8374
    },
    {
        "facility": "Medi-Clinic Ermelo",
        "lat": -26.542854541,
        "lon": 29.988005229
    },
    {
        "facility": "Medi-Clinic Gariep",
        "lat": -28.763049585,
        "lon": 24.737959876
    },
    {
        "facility": "Medi-Clinic Geneva Clinic (Pty) Ltd",
        "lat": -33.956562047,
        "lon": 22.452011163
    },
    {
        "facility": "Medi-Clinic George",
        "lat": -33.956967297,
        "lon": 22.456234385
    },
    {
        "facility": "Medi-Clinic Heart Hospital",
        "lat": -25.748412749,
        "lon": 28.206578147
    },
    {
        "facility": "Medi-Clinic Hermanus",
        "lat": -34.422900241,
        "lon": 19.226604401
    },
    {
        "facility": "Medi-Clinic Highveld",
        "lat": -26.492006636,
        "lon": 29.231844573
    },
    {
        "facility": "Medi-Clinic Hoogland",
        "lat": -28.229073516,
        "lon": 28.321642126
    },
    {
        "facility": "Medi-Clinic Howick Private Hospital",
        "lat": -29.477733333,
        "lon": 30.22
    },
    {
        "facility": "Medi-Clinic Hydromed Hospital - Bloemfontein",
        "lat": -29.108944486,
        "lon": 26.203110278
    },
    {
        "facility": "Medi-Clinic Hydromed Hospital - Welkom",
        "lat": -27.986966667,
        "lon": 26.730033333
    },
    {
        "facility": "Medi-Clinic Kathu Pvt Hospital",
        "lat": -27.6977108,
        "lon": 23.050546542
    },
    {
        "facility": "Medi-Clinic Kimberley",
        "lat": -28.744722222,
        "lon": 24.7725
    },
    {
        "facility": "Medi-Clinic Klein Karoo",
        "lat": -33.586185922,
        "lon": 22.185091382
    },
    {
        "facility": "Medi-Clinic Kloof",
        "lat": -25.810616667,
        "lon": 28.263616667
    },
    {
        "facility": "Medi-Clinic Lephalale",
        "lat": -23.686388889,
        "lon": 27.697777778
    },
    {
        "facility": "Medi-Clinic Limpopo",
        "lat": -23.908611111,
        "lon": 29.463333333
    },
    {
        "facility": "Medi-Clinic Louis Leipoldt",
        "lat": -33.901400167,
        "lon": 18.613053595
    },
    {
        "facility": "Medi-Clinic Marapong Hospital",
        "lat": -23.65814326,
        "lon": 27.617335303
    },
    {
        "facility": "Medi-Clinic Medforum",
        "lat": -25.748333333,
        "lon": 28.198888889
    },
    {
        "facility": "Medi-Clinic Midstream",
        "lat": -25.928462104,
        "lon": 28.183209561
    },
    {
        "facility": "Medi-Clinic Milnerton",
        "lat": -33.865523796,
        "lon": 18.506694761
    },
    {
        "facility": "Medi-Clinic Morningside",
        "lat": -26.095088922,
        "lon": 28.056087034
    },
    {
        "facility": "Medi-Clinic Muelmed",
        "lat": -25.746852951,
        "lon": 28.207763415
    },
    {
        "facility": "Medi-Clinic Nelspruit",
        "lat": -25.493342029,
        "lon": 30.962007288
    },
    {
        "facility": "Medi-Clinic Newcastle Private Hospital",
        "lat": -27.76855,
        "lon": 29.93145
    },
    {
        "facility": "Medi-Clinic Oudtshoorn",
        "lat": -33.586733287,
        "lon": 22.185081679
    },
    {
        "facility": "Medi-Clinic Paarl",
        "lat": -33.718527346,
        "lon": 18.969817776
    },
    {
        "facility": "Medi-Clinic Panorama",
        "lat": -33.875133333,
        "lon": 18.578016667
    },
    {
        "facility": "Medi-Clinic Panorama Rehab Hospital",
        "lat": -33.875658351,
        "lon": 18.576992997
    },
    {
        "facility": "Medi-Clinic Pietermaritzburg",
        "lat": -29.609583333,
        "lon": 30.38835
    },
    {
        "facility": "Medi-Clinic Plettenberg Bay",
        "lat": -34.053257334,
        "lon": 23.36506015
    },
    {
        "facility": "Medi-Clinic Potchefstroom",
        "lat": -26.689164891,
        "lon": 27.081393278
    },
    {
        "facility": "Medi-Clinic Pretoria Gynaelogical Hospital",
        "lat": -25.756178312,
        "lon": 28.204934166
    },
    {
        "facility": "Medi-Clinic Pretoria Heart Hospital",
        "lat": -25.749146579,
        "lon": 28.2069438
    },
    {
        "facility": "Medi-Clinic Sandton",
        "lat": -26.077472824,
        "lon": 28.011699638
    },
    {
        "facility": "Medi-Clinic Stellenbosch",
        "lat": -33.944633333,
        "lon": 18.850466667
    },
    {
        "facility": "Medi-Clinic Strand Private Hospital",
        "lat": -34.115016667,
        "lon": 18.835866667
    },
    {
        "facility": "Medi-Clinic Trimed",
        "lat": -26.648508597,
        "lon": 27.975099351
    },
    {
        "facility": "Medi-Clinic Tzaneen Private Hospital",
        "lat": -23.82193171,
        "lon": 30.152781409
    },
    {
        "facility": "Medi-Clinic Upington",
        "lat": -28.4394,
        "lon": 21.267966667
    },
    {
        "facility": "Medi-Clinic Vereeniging",
        "lat": -26.667761901,
        "lon": 27.928129688
    },
    {
        "facility": "Medi-Clinic Vergelegen",
        "lat": -34.090294367,
        "lon": 18.858093467
    },
    {
        "facility": "Medi-Clinic Victoria Hospital, Tongaat",
        "lat": -29.573716667,
        "lon": 31.118166667
    },
    {
        "facility": "Medi-Clinic Welkom",
        "lat": -27.987511146,
        "lon": 26.730009684
    },
    {
        "facility": "Medi-Clinic Worcester",
        "lat": -33.643558506,
        "lon": 19.451338031
    },
    {
        "facility": "Medipark Medical Center",
        "lat": -25.894444444,
        "lon": 28.142777778
    },
    {
        "facility": "Medsac Plettenburg",
        "lat": -34.049663023,
        "lon": 23.362926236
    },
    {
        "facility": "Medsac Strand",
        "lat": -34.114823669,
        "lon": 18.83656089
    },
    {
        "facility": "Medstep",
        "lat": -25.710816376,
        "lon": 28.19493077
    },
    {
        "facility": "Med-Step Stepdown",
        "lat": -25.71095063,
        "lon": 28.193826695
    },
    {
        "facility": "MEERENBOSCH  FRAILC",
        "lat": -33.822478379,
        "lon": 18.636850468
    },
    {
        "facility": "Meerensee Medi Cross",
        "lat": -28.78335,
        "lon": 32.097716667
    },
    {
        "facility": "Meldene Medi Cross",
        "lat": -26.177583333,
        "lon": 28.00055
    },
    {
        "facility": "Melomed Belville Medical Centre",
        "lat": -33.901396557,
        "lon": 18.624659909
    },
    {
        "facility": "Melomed Gatesville Medical Centre",
        "lat": -33.970606568,
        "lon": 18.532822429
    },
    {
        "facility": "Melomed Jan S. Marais Clinic",
        "lat": -33.903132137,
        "lon": 18.628630883
    },
    {
        "facility": "Melomed Mitchell's Plain Medical Centre",
        "lat": -34.04905,
        "lon": 18.622783333
    },
    {
        "facility": "Melomed Richardsbay Hospital",
        "lat": -28.7654653,
        "lon": 31.931478
    },
    {
        "facility": "Melomed Tokai Hospital",
        "lat": -34.067990069,
        "lon": 18.458497874
    },
    {
        "facility": "Messina Hospital",
        "lat": -22.341666667,
        "lon": 30.043611111
    },
    {
        "facility": "Metamorphosis Clinic",
        "lat": -26.179266667,
        "lon": 28.289033333
    },
    {
        "facility": "Metsimaholo Hospital",
        "lat": -26.80245,
        "lon": 27.82725
    },
    {
        "facility": "Meyerton Day Clinic",
        "lat": -26.5583,
        "lon": 28.023733333
    },
    {
        "facility": "Michael Mapongwana Hospital",
        "lat": -34.048516667,
        "lon": 18.67525
    },
    {
        "facility": "Middelburg Hospital",
        "lat": -25.774818956,
        "lon": 29.451040228
    },
    {
        "facility": "Midland Hospital",
        "lat": -32.261483333,
        "lon": 24.536083333
    },
    {
        "facility": "Midlands Hospital",
        "lat": -29.476233333,
        "lon": 30.204683333
    },
    {
        "facility": "Midvaal Hospital",
        "lat": -26.66385,
        "lon": 27.968916667
    },
    {
        "facility": "Mierder Park Medi Cross",
        "lat": -26.727666667,
        "lon": 27.09415
    },
    {
        "facility": "Millenium Centre",
        "lat": -26.198337419,
        "lon": 28.073973593
    },
    {
        "facility": "Millsite TB Hospital",
        "lat": -26.179816667,
        "lon": 27.762516667
    },
    {
        "facility": "Mitchell's Plain Day Hospital",
        "lat": -34.04605,
        "lon": 18.621416667
    },
    {
        "facility": "Mmamethlake Hospital",
        "lat": -25.107777777,
        "lon": 28.544166666
    },
    {
        "facility": "Moira Sentrum OAH",
        "lat": -26.089166667,
        "lon": 27.778055556
    },
    {
        "facility": "Mokopane Hospital",
        "lat": -24.1535761,
        "lon": 28.988999433
    },
    {
        "facility": "Molecular Imaging",
        "lat": -23.90957676,
        "lon": 29.46459736
    },
    {
        "facility": "Molteno Hospital",
        "lat": -31.395683333,
        "lon": 26.362133333
    },
    {
        "facility": "Montagu Hospital",
        "lat": -33.789383333,
        "lon": 20.1202
    },
    {
        "facility": "Montamed Step-Down",
        "lat": -25.660359173,
        "lon": 28.256925965
    },
    {
        "facility": "Monte Vista Clinic",
        "lat": -33.8766,
        "lon": 18.555533333
    },
    {
        "facility": "Montobelo Hospital",
        "lat": -29.437777778,
        "lon": 30.816666667
    },
    {
        "facility": "Monument Medi Cross",
        "lat": -26.09105,
        "lon": 28.237483333
    },
    {
        "facility": "Mooi Gelee Clinic",
        "lat": -25.785183333,
        "lon": 28.307016667
    },
    {
        "facility": "Mooimed Hospital",
        "lat": -26.700433333,
        "lon": 27.09605
    },
    {
        "facility": "Morehill Clinic",
        "lat": -26.141954351,
        "lon": 28.349090413
    },
    {
        "facility": "Moreteletsi Community Hospital",
        "lat": -25.506,
        "lon": 27.098133333
    },
    {
        "facility": "Moria Sentrum OAH",
        "lat": -26.089166667,
        "lon": 27.778055556
    },
    {
        "facility": "Moroka Community Hospital",
        "lat": -29.210383333,
        "lon": 26.838733333
    },
    {
        "facility": "Moses Ketani Hospital",
        "lat": -25.380280472,
        "lon": 27.056660896
    },
    {
        "facility": "Mossel Bay Hospital",
        "lat": -34.017983333,
        "lon": 22.136316667
    },
    {
        "facility": "Mosvold Hospital",
        "lat": -27.132016667,
        "lon": 32.00315
    },
    {
        "facility": "Motherwell Hospital",
        "lat": -33.810847611,
        "lon": 25.599173046
    },
    {
        "facility": "Moto Haven Frailcare",
        "lat": -29.853613086,
        "lon": 30.993273416
    },
    {
        "facility": "Mount Ayliff Hospital",
        "lat": -30.810083333,
        "lon": 29.3664
    },
    {
        "facility": "Mowbray Maternity Hospital",
        "lat": -33.948583333,
        "lon": 18.474933333
    },
    {
        "facility": "Mpumelelo Clinic",
        "lat": -25.985555556,
        "lon": 28.208611111
    },
    {
        "facility": "Mseleni",
        "lat": -26.661486326,
        "lon": 27.968996985
    },
    {
        "facility": "Murchison Hospital",
        "lat": -30.727226953,
        "lon": 30.343224271
    },
    {
        "facility": "Naas Clinic",
        "lat": -25.670833333,
        "lon": 31.861666667
    },
    {
        "facility": "Nala (Bothaville) Hospital",
        "lat": -27.389236435,
        "lon": 26.619904568
    },
    {
        "facility": "Natalspruit Hospital",
        "lat": -26.3575,
        "lon": 28.223055556
    },
    {
        "facility": "National Renal Unit - EL",
        "lat": -32.999944558,
        "lon": 27.897507569
    },
    {
        "facility": "National Renal Unit-QTN",
        "lat": -31.896388889,
        "lon": 26.876388889
    },
    {
        "facility": "Nazareth House (Dbn)",
        "lat": -29.8538193,
        "lon": 30.9885757
    },
    {
        "facility": "Nazareth House (Jhb)",
        "lat": -26.184868013,
        "lon": 28.057978079
    },
    {
        "facility": "Nazareth House (PE)",
        "lat": -33.962533475,
        "lon": 25.603673399
    },
    {
        "facility": "Nazareth House (Pta)",
        "lat": -25.776444094,
        "lon": 28.225592207
    },
    {
        "facility": "Nedpark Clinic",
        "lat": -25.750116667,
        "lon": 28.203966667
    },
    {
        "facility": "Nelmed Medi-Cross",
        "lat": -25.476111111,
        "lon": 30.977777778
    },
    {
        "facility": "Nelson Mandela Academic Hospital",
        "lat": -31.589733333,
        "lon": 28.782016667
    },
    {
        "facility": "Nelspoort Training & Rehab Centre",
        "lat": -32.113416534,
        "lon": 23.004474012
    },
    {
        "facility": "Nessie Knight Hospital",
        "lat": -31.009999341,
        "lon": 28.682865772
    },
    {
        "facility": "Netcare Akasia Clinic",
        "lat": -25.673330996,
        "lon": 28.107453204
    },
    {
        "facility": "Netcare Alberlito Hospital",
        "lat": -29.530056796,
        "lon": 31.202360748
    },
    {
        "facility": "Netcare Alberton Hospital",
        "lat": -26.274457479,
        "lon": 28.127522990
    },
    {
        "facility": "Netcare Blaauwberg Hospital",
        "lat": -33.803728303,
        "lon": 18.484086861
    },
    {
        "facility": "Netcare Bouganville Hospital",
        "lat": -25.716587121,
        "lon": 28.152967972
    },
    {
        "facility": "Netcare Ceres Private Hospital",
        "lat": -33.360116667,
        "lon": 19.308866667
    },
    {
        "facility": "Netcare Christiaan Barnard Memorial Hospital",
        "lat": -33.918175907,
        "lon": 18.430715395
    },
    {
        "facility": "Netcare Clinton Clinic",
        "lat": -26.273223667,
        "lon": 28.120682072
    },
    {
        "facility": "Netcare Constantia Clinic",
        "lat": -26.148038321,
        "lon": 27.900826184
    },
    {
        "facility": "Netcare Cuyler  Clinic",
        "lat": -33.76527767,
        "lon": 25.392541577
    },
    {
        "facility": "Netcare Femina Clinic",
        "lat": -25.740314327,
        "lon": 28.200960596
    },
    {
        "facility": "Netcare Ferncrest Hospital",
        "lat": -25.648058448,
        "lon": 27.214536812
    },
    {
        "facility": "Netcare Garden City Clinic",
        "lat": -26.197365066,
        "lon": 28.006810417
    },
    {
        "facility": "Netcare Greenacres Hospital",
        "lat": -33.951627041,
        "lon": 25.579176052
    },
    {
        "facility": "Netcare Jakaranda Hospital",
        "lat": -25.759888537,
        "lon": 28.211910495
    },
    {
        "facility": "Netcare Kingsway Hospital",
        "lat": -30.032540418,
        "lon": 30.905311523
    },
    {
        "facility": "Netcare Kroon Hospital",
        "lat": -27.653862915,
        "lon": 27.230821158
    },
    {
        "facility": "Netcare Krugersdorp Private Hospital",
        "lat": -26.105073911,
        "lon": 27.775096844
    },
    {
        "facility": "Netcare Kuils River Hospital",
        "lat": -33.919714857,
        "lon": 18.675100375
    },
    {
        "facility": "Netcare Lakeview Clinic",
        "lat": -26.184456103,
        "lon": 28.312232873
    },
    {
        "facility": "Netcare Linksfield Park Clinic",
        "lat": -26.159466451,
        "lon": 28.095285808
    },
    {
        "facility": "Netcare Linkwood Clinic",
        "lat": -26.159438441,
        "lon": 28.095285544
    },
    {
        "facility": "Netcare Linmed Hospital",
        "lat": -26.145396518,
        "lon": 28.330838131
    },
    {
        "facility": "Netcare Margate Private Hospital",
        "lat": -30.861980278,
        "lon": 30.358037071
    },
    {
        "facility": "Netcare Milpark Hospital",
        "lat": -26.18087993,
        "lon": 28.018523049
    },
    {
        "facility": "Netcare Montana Private Hospital",
        "lat": -25.674093049,
        "lon": 28.244350554
    },
    {
        "facility": "Netcare Moot Algemene Hospitaal",
        "lat": -25.713611111,
        "lon": 28.218055556
    },
    {
        "facility": "Netcare Mulbarton Hospital",
        "lat": -26.298200288,
        "lon": 28.053922522
    },
    {
        "facility": "Netcare N1 City Hospital",
        "lat": -33.893601998,
        "lon": 18.559140534
    },
    {
        "facility": "Netcare N17 Hospital",
        "lat": -26.272502197,
        "lon": 28.428131829
    },
    {
        "facility": "Netcare Olivedale Clinic",
        "lat": -26.054722015,
        "lon": 27.972049031
    },
    {
        "facility": "Netcare Optiklin Eye Hospital",
        "lat": -26.17820022,
        "lon": 28.288733238
    },
    {
        "facility": "Netcare Park Lane Clinic",
        "lat": -26.181498177,
        "lon": 28.046748344
    },
    {
        "facility": "Netcare Parklands Hospital",
        "lat": -29.834078389,
        "lon": 30.998448436
    },
    {
        "facility": "Netcare Pelonomi Hospital",
        "lat": -29.13966244032803,
        "lon": 26.244770011279975
    },
    {
        "facility": "Netcare Pholoso Hospital",
        "lat": -23.902311099,
        "lon": 29.491448616
    },
    {
        "facility": "Netcare Pinehaven Hospital",
        "lat": -26.061590428,
        "lon": 27.828465674
    },
    {
        "facility": "Netcare Port Alfred Hospital",
        "lat": -33.595163561,
        "lon": 26.884065233
    },
    {
        "facility": "Netcare Pretoria East Hospital",
        "lat": -25.819443265,
        "lon": 28.304472139
    },
    {
        "facility": "Netcare Protea Day Clinic",
        "lat": -26.106863321,
        "lon": 27.777449865
    },
    {
        "facility": "Netcare Rehabilitation Hospital",
        "lat": -26.18782455,
        "lon": 28.011517555
    },
    {
        "facility": "Netcare Rosebank Clinic",
        "lat": -26.146313298,
        "lon": 28.038863239
    },
    {
        "facility": "Netcare St. Anne's Hospital",
        "lat": -29.602658143,
        "lon": 30.385218174
    },
    {
        "facility": "Netcare St. Augustine's Hospital",
        "lat": -29.855011204,
        "lon": 30.990548483
    },
    {
        "facility": "Netcare Sunninghill Hospital",
        "lat": -26.038017685,
        "lon": 28.070267752
    },
    {
        "facility": "Netcare Sunward Park Hospital",
        "lat": -26.260653039,
        "lon": 28.257367911
    },
    {
        "facility": "Netcare The Bay Hospital",
        "lat": -28.750403911,
        "lon": 32.052580052
    },
    {
        "facility": "Netcare Umhlanga Hospital",
        "lat": -29.728118954,
        "lon": 31.068754142
    },
    {
        "facility": "Netcare Union Hospital",
        "lat": -26.269823459,
        "lon": 28.119526815
    },
    {
        "facility": "Netcare Unitas Hospital",
        "lat": -25.831538495,
        "lon": 28.196954866
    },
    {
        "facility": "Netcare Universitas Hospital",
        "lat": -29.117864028088405,
        "lon": 26.183787895933673
    },
    {
        "facility": "Netcare Vaal Park Hospital",
        "lat": -26.773402863,
        "lon": 27.839835683
    },
    {
        "facility": "Netcare Waterfall City Hospital",
        "lat": -26.014545323,
        "lon": 28.102683933
    },
    {
        "facility": "Newcastle Hospital",
        "lat": -27.762683333,
        "lon": 29.940166667
    },
    {
        "facility": "Newlands Surgical Clinic",
        "lat": -33.980333333,
        "lon": 18.465283333
    },
    {
        "facility": "Ngodwana Medical Station",
        "lat": -25.577839811,
        "lon": 30.664390073
    },
    {
        "facility": "Ngwelezana Hospital",
        "lat": -28.779523698,
        "lon": 31.864357064
    },
    {
        "facility": "Nick Brodenstein Provincial Hospital",
        "lat": -27.188359304,
        "lon": 25.982631417
    },
    {
        "facility": "Niemeyer Hospital",
        "lat": -27.661666667,
        "lon": 30.323633333
    },
    {
        "facility": "Nigel Hospital",
        "lat": -27.663983333,
        "lon": 28.414616667
    },
    {
        "facility": "Nite-Doc Hospital",
        "lat": -25.876233333,
        "lon": 29.225866667
    },
    {
        "facility": "Nkandla Hospital",
        "lat": -28.6218,
        "lon": 31.092083333
    },
    {
        "facility": "Nkhensani Hospital",
        "lat": -23.317070699,
        "lon": 30.711039855
    },
    {
        "facility": "Nkonjeni Hospital",
        "lat": -28.230833333,
        "lon": 31.470333333
    },
    {
        "facility": "Nkqubela Chest Hospital",
        "lat": -32.929233333,
        "lon": 27.722833333
    },
    {
        "facility": "Nompumelelo Hospital",
        "lat": -33.196366667,
        "lon": 27.1162
    },
    {
        "facility": "Nongoma/Philani Pvt Hosp",
        "lat": -27.9193447,
        "lon": 31.646458
    },
    {
        "facility": "Northcliff Day Clinic",
        "lat": -26.133116667,
        "lon": 27.97425
    },
    {
        "facility": "Northdale Hospital",
        "lat": -29.577866158,
        "lon": 30.401795418
    },
    {
        "facility": "Northdene Medical Centre",
        "lat": -29.866466667,
        "lon": 30.88595
    },
    {
        "facility": "Northpine Medi Cross",
        "lat": -33.87130728,
        "lon": 18.711425462
    },
    {
        "facility": "Novalis House",
        "lat": -25.962715122,
        "lon": 28.088698521
    },
    {
        "facility": "Ntunjambili Hospital",
        "lat": -28.967383333,
        "lon": 30.8638
    },
    {
        "facility": "Nyala Clinic",
        "lat": -27.901666667,
        "lon": 26.677777778
    },
    {
        "facility": "O.R. TAMBO INTL",
        "lat": -26.134166667,
        "lon": 28.228611111
    },
    {
        "facility": "Oasim Private Hospital",
        "lat": -33.962483333,
        "lon": 25.615083333
    },
    {
        "facility": "Oasis Rehab Centre",
        "lat": -33.890271468,
        "lon": 18.507373744
    },
    {
        "facility": "Oatlands Care Centre",
        "lat": -29.477777778,
        "lon": 30.2175
    },
    {
        "facility": "Occumed Eye & Lazer",
        "lat": -26.742640095,
        "lon": 27.820374874
    },
    {
        "facility": "Odi Community Hospital",
        "lat": -25.375016667,
        "lon": 28.235616667
    },
    {
        "facility": "Old People's Home",
        "lat": -26.190155172,
        "lon": 28.312059659
    },
    {
        "facility": "Old Primecure clinic (Dr Ntsi",
        "lat": -26.697131557,
        "lon": 27.836759573
    },
    {
        "facility": "Oliander Park OAH",
        "lat": -26.86209606,
        "lon": 26.64208872
    },
    {
        "facility": "Olifantshoek Hospital",
        "lat": -27.940716667,
        "lon": 22.735033333
    },
    {
        "facility": "Ons Herberg",
        "lat": -25.815591986,
        "lon": 28.743854603
    },
    {
        "facility": "Onse Tuiste OAH",
        "lat": -33.899691452,
        "lon": 18.632255352
    },
    {
        "facility": "Optenhorst Rehab Clinic",
        "lat": -33.71705,
        "lon": 18.968266667
    },
    {
        "facility": "Optimed Learning Centre",
        "lat": -26.342561617,
        "lon": 28.093215827
    },
    {
        "facility": "Oral & Dental Hospital",
        "lat": -25.732983333,
        "lon": 28.201
    },
    {
        "facility": "Oranje Hospital",
        "lat": -29.1327,
        "lon": 26.207733333
    },
    {
        "facility": "Orchard Lodge Step-Down",
        "lat": -26.155674514,
        "lon": 28.081765922
    },
    {
        "facility": "Oribi Airport - PMB",
        "lat": -29.643655318,
        "lon": 30.396907132
    },
    {
        "facility": "Orsmond SANTA Centre",
        "lat": -33.766316753,
        "lon": 25.392051962
    },
    {
        "facility": "Oshoek border post",
        "lat": -26.212522517,
        "lon": 30.989166662
    },
    {
        "facility": "Osindisweni Hospital",
        "lat": -29.608239647,
        "lon": 30.983357226
    },
    {
        "facility": "Othello Old Age Home",
        "lat": -33.8817,
        "lon": 18.6833
    },
    {
        "facility": "Otto Du Plessis Hospital",
        "lat": -34.536266667,
        "lon": 20.034183333
    },
    {
        "facility": "Oude Weshof OAH",
        "lat": -33.858031278,
        "lon": 18.606094852
    },
    {
        "facility": "Oudtsoorn Hospital",
        "lat": -33.610816667,
        "lon": 22.2004
    },
    {
        "facility": "Our Parents Home",
        "lat": -26.147222222,
        "lon": 28.074722222
    },
    {
        "facility": "P.Z. Meyer Hospital",
        "lat": -34.024916667,
        "lon": 24.76135
    },
    {
        "facility": "Paarl East T.C. Newman Memorial Hospital",
        "lat": -33.721683333,
        "lon": 18.9873
    },
    {
        "facility": "Paarl Medi-Cross",
        "lat": -33.741966667,
        "lon": 18.967016667
    },
    {
        "facility": "Paarl Provincial Hospital",
        "lat": -33.726435719,
        "lon": 18.970253066
    },
    {
        "facility": "Palm Garden Retreat",
        "lat": -33.922057654,
        "lon": 18.383480384
    },
    {
        "facility": "Palms Renaissance Ret. V",
        "lat": -25.733363043,
        "lon": 28.306607399
    },
    {
        "facility": "Panorama OAH",
        "lat": -26.892777778,
        "lon": 26.6525
    },
    {
        "facility": "Panorama Old Age Home",
        "lat": -26.10991778,
        "lon": 27.852947623
    },
    {
        "facility": "Panorama Palms",
        "lat": -33.884413465,
        "lon": 18.574121521
    },
    {
        "facility": "Park Care Frailcare",
        "lat": -26.177902279,
        "lon": 28.028932538
    },
    {
        "facility": "Parkmed Neuro Clinic",
        "lat": -26.872833333,
        "lon": 26.66125
    },
    {
        "facility": "Parkview Clinic",
        "lat": -29.852303694,
        "lon": 31.03679877
    },
    {
        "facility": "Parkwood Clinic",
        "lat": -34.032233333,
        "lon": 18.492316667
    },
    {
        "facility": "Parkwood Day Hospital",
        "lat": -33.963666667,
        "lon": 25.6111
    },
    {
        "facility": "Parow Medi Cross",
        "lat": -33.899816667,
        "lon": 18.586433333
    },
    {
        "facility": "Parys Provincial Hospital",
        "lat": -26.895906639,
        "lon": 27.472069799
    },
    {
        "facility": "Paul Kruger Memorial Hospital",
        "lat": -25.677647768,
        "lon": 27.245644794
    },
    {
        "facility": "Pelonomi Private Hospital",
        "lat": -29.14235,
        "lon": 26.24715
    },
    {
        "facility": "Phalaborwa Hospital",
        "lat": -23.933270593,
        "lon": 31.137210977
    },
    {
        "facility": "Phekolong Hospital",
        "lat": -28.2175,
        "lon": 28.326388889
    },
    {
        "facility": "Phelang Private Hospital",
        "lat": -25.714833333,
        "lon": 28.375433333
    },
    {
        "facility": "Phetolo Medical Centre",
        "lat": -25.689061472,
        "lon": 31.78936649
    },
    {
        "facility": "Philadelphia Hospital",
        "lat": -25.258333333,
        "lon": 29.148888889
    },
    {
        "facility": "Philani/Nongoma Pvt Hosp",
        "lat": -27.9193447,
        "lon": 31.646458
    },
    {
        "facility": "Phoenix Rehab Centre",
        "lat": -29.716783333,
        "lon": 31.003916667
    },
    {
        "facility": "Pholansikazi Clinic",
        "lat": -25.158055556,
        "lon": 31.121388889
    },
    {
        "facility": "Pholosong Hospital",
        "lat": -26.339216667,
        "lon": 28.375983333
    },
    {
        "facility": "Phumelela District Hospital",
        "lat": -27.433498799,
        "lon": 29.156035296
    },
    {
        "facility": "Phumlani",
        "lat": -26.159444444,
        "lon": 27.720277778
    },
    {
        "facility": "Phuthuloha Hospital",
        "lat": -28.868866667,
        "lon": 27.879633333
    },
    {
        "facility": "Piet Plessis Hospital",
        "lat": -26.1636,
        "lon": 24.410283333
    },
    {
        "facility": "Piet Retief Hospital",
        "lat": -27.015016667,
        "lon": 30.805933333
    },
    {
        "facility": "Pinetown Day Clinic",
        "lat": -29.816483333,
        "lon": 30.859283333
    },
    {
        "facility": "Pinetown Medi Cross",
        "lat": -29.815766667,
        "lon": 30.8638
    },
    {
        "facility": "Pofadder Hospital",
        "lat": -29.12865,
        "lon": 19.39505
    },
    {
        "facility": "Polifin Medical Centre",
        "lat": -26.818983333,
        "lon": 27.8452
    },
    {
        "facility": "Polokwane Hospice",
        "lat": -23.8925,
        "lon": 29.4575
    },
    {
        "facility": "Polokwane Military sick bay",
        "lat": -23.864444444,
        "lon": 29.454722222
    },
    {
        "facility": "Polokwane Prov Hospital",
        "lat": -23.895756118,
        "lon": 29.457763245
    },
    {
        "facility": "Pongola Hospital",
        "lat": -27.377596816,
        "lon": 31.618481203
    },
    {
        "facility": "Pongola(Itshelejuba) Hosp",
        "lat": -27.276790789,
        "lon": 31.34759424
    },
    {
        "facility": "Port Alfred General Hospital",
        "lat": -33.593583333,
        "lon": 26.892983333
    },
    {
        "facility": "Port Elizabeth Airport",
        "lat": -33.98418417,
        "lon": 25.61073392
    },
    {
        "facility": "Port Elizabeth Provincial Hospital",
        "lat": -33.9586,
        "lon": 25.599066667
    },
    {
        "facility": "Port Nolloth Hospital",
        "lat": -29.252333333,
        "lon": 16.870216667
    },
    {
        "facility": "Port Shepstone Hospital",
        "lat": -30.7438,
        "lon": 30.452333333
    },
    {
        "facility": "Porterville Hospital",
        "lat": -33.010768206,
        "lon": 18.995392629
    },
    {
        "facility": "Postmasburg Hospital",
        "lat": -28.327998576,
        "lon": 23.059804046
    },
    {
        "facility": "Postmasburg Prov",
        "lat": -28.318366612,
        "lon": 23.071393117
    },
    {
        "facility": "Potchefstroom Hospital",
        "lat": -26.729310698,
        "lon": 27.083751945
    },
    {
        "facility": "Potchefstroom Medi Cross",
        "lat": -26.710833333,
        "lon": 27.093888889
    },
    {
        "facility": "Potmed Clinic",
        "lat": -24.188091601,
        "lon": 29.011446334
    },
    {
        "facility": "Pretoria Eye Institute",
        "lat": -25.747583333,
        "lon": 28.2089
    },
    {
        "facility": "Pretoria North Medi Cross",
        "lat": -25.673128302,
        "lon": 28.170932773
    },
    {
        "facility": "Pretoria Urology Hospital",
        "lat": -25.744011257,
        "lon": 28.237320144
    },
    {
        "facility": "Pretoria West Hospital",
        "lat": -25.735166667,
        "lon": 28.140233333
    },
    {
        "facility": "Pretoria West Medi Cross",
        "lat": -25.748566964,
        "lon": 28.149247349
    },
    {
        "facility": "Pri-Med Clinic",
        "lat": -29.783916667,
        "lon": 30.972366667
    },
    {
        "facility": "Pri-Med Steepdown Bay",
        "lat": -28.742777778,
        "lon": 32.050833333
    },
    {
        "facility": "Prince Albert Hospital",
        "lat": -33.217165207,
        "lon": 22.026047832
    },
    {
        "facility": "Prince Mshiyeni Hospital",
        "lat": -29.954059655,
        "lon": 30.934552071
    },
    {
        "facility": "Protea Glen Clinic",
        "lat": -26.272777778,
        "lon": 27.805277778
    },
    {
        "facility": "Protea Retirement Village",
        "lat": -25.829444444,
        "lon": 28.186666667
    },
    {
        "facility": "PUTFONTEIN POLICE STATION",
        "lat": -26.102,
        "lon": 28.4188
    },
    {
        "facility": "Queen Alexandra",
        "lat": -26.158055555,
        "lon": 28.087222222
    },
    {
        "facility": "Queen Olive O.a.h",
        "lat": -25.636755678,
        "lon": 28.303588361
    },
    {
        "facility": "Queens High School",
        "lat": -26.185461492,
        "lon": 28.09888385
    },
    {
        "facility": "R.K.Khan Hospital",
        "lat": -29.914504327,
        "lon": 30.886730805
    },
    {
        "facility": "Radie Kotzé Hospital",
        "lat": -32.90515,
        "lon": 18.75585
    },
    {
        "facility": "Rahima Moosa",
        "lat": -26.1886,
        "lon": 27.973116667
    },
    {
        "facility": "Ramot Hospital",
        "lat": -33.894083333,
        "lon": 18.600983333
    },
    {
        "facility": "Rand Clinic",
        "lat": -26.185316667,
        "lon": 28.049916667
    },
    {
        "facility": "Rand Mutual Hospital",
        "lat": -26.214983333,
        "lon": 28.043233333
    },
    {
        "facility": "Randburg Day Clinic",
        "lat": -26.11295,
        "lon": 28.014833333
    },
    {
        "facility": "Randburg Medi Cross",
        "lat": -26.1099,
        "lon": 27.990433333
    },
    {
        "facility": "Randfontein Hospital",
        "lat": -26.169383333,
        "lon": 27.6988
    },
    {
        "facility": "Raslouw Private Hospital",
        "lat": -25.85537970321275,
        "lon": 28.10988229231207
    },
    {
        "facility": "Red Cross Children's Hospital",
        "lat": -33.954434544,
        "lon": 18.488009069
    },
    {
        "facility": "Red flag - Psyciatric pt",
        "lat": -26.151749495,
        "lon": 27.859528184
    },
    {
        "facility": "Reitz Provincial Hospital",
        "lat": -27.801733333,
        "lon": 28.427
    },
    {
        "facility": "Renaissance Ret. Village",
        "lat": -26.292145013,
        "lon": 28.072567384
    },
    {
        "facility": "Reserve Village",
        "lat": -33.383666667,
        "lon": 26.40075
    },
    {
        "facility": "Residential",
        "lat": -25.489943581,
        "lon": 28.104012474
    },
    {
        "facility": "Retreat Clinic",
        "lat": -34.05826981,
        "lon": 18.480276082
    },
    {
        "facility": "Revemingo Care Centre",
        "lat": -25.736398293,
        "lon": 28.191925798
    },
    {
        "facility": "Richards Bay Medi Cross",
        "lat": -28.75,
        "lon": 31.054616667
    },
    {
        "facility": "Richmond Hospital KZN",
        "lat": -29.873416667,
        "lon": 30.269783333
    },
    {
        "facility": "Richmond Hospital NC",
        "lat": -31.413833333,
        "lon": 23.94365
    },
    {
        "facility": "Ridge Wood Retirement Vill",
        "lat": -29.713331077,
        "lon": 31.040034967
    },
    {
        "facility": "Riemland Hospital",
        "lat": -27.282516667,
        "lon": 28.493983333
    },
    {
        "facility": "Rietfontein Hospital",
        "lat": -26.1383,
        "lon": 28.126233333
    },
    {
        "facility": "Rietvlei Hospital",
        "lat": -30.4875,
        "lon": 29.826111111
    },
    {
        "facility": "River Park OAH",
        "lat": -25.754166667,
        "lon": 29.459444444
    },
    {
        "facility": "Riverlea Retirement Village",
        "lat": -26.206564815,
        "lon": 27.96211012
    },
    {
        "facility": "Riversdale Hospital",
        "lat": -34.093293751,
        "lon": 21.254675919
    },
    {
        "facility": "Riverview Manor Hospital",
        "lat": -29.79345,
        "lon": 29.4985
    },
    {
        "facility": "Rob Ferreira Hospital",
        "lat": -25.477593452,
        "lon": 30.971428204
    },
    {
        "facility": "Robertson Hospital",
        "lat": -33.792366667,
        "lon": 19.8947
    },
    {
        "facility": "Robin Trust Stepdown",
        "lat": -33.940833333,
        "lon": 18.488888889
    },
    {
        "facility": "Rondebosch Private Hospit",
        "lat": -33.954388262,
        "lon": 18.490586399
    },
    {
        "facility": "Roodepoort OAH",
        "lat": -26.164922583,
        "lon": 27.887384163
    },
    {
        "facility": "RPM - Hospital & Base",
        "lat": -25.698338326,
        "lon": 27.356444181
    },
    {
        "facility": "RPM B Hostel Clinic",
        "lat": -25.697363711,
        "lon": 27.326816693
    },
    {
        "facility": "RPM-Entabeni Clinic",
        "lat": -25.658611111,
        "lon": 27.328611111
    },
    {
        "facility": "RPM-Kanana Clinic",
        "lat": -25.6525,
        "lon": 27.300555556
    },
    {
        "facility": "RPM-Waterval Clinic",
        "lat": -25.675277778,
        "lon": 27.3225
    },
    {
        "facility": "Rustenburg Kloof Lodge",
        "lat": -25.685584816,
        "lon": 27.193770893
    },
    {
        "facility": "Rynpark 1",
        "lat": -26.140590544,
        "lon": 28.349951038
    },
    {
        "facility": "Rynpark 2 & 3",
        "lat": -26.139806279,
        "lon": 28.348830718
    },
    {
        "facility": "Rynpark 4",
        "lat": -26.139043982,
        "lon": 28.344895203
    },
    {
        "facility": "Rynpark 5",
        "lat": -26.137432708,
        "lon": 28.342607398
    },
    {
        "facility": "Rynpark 6",
        "lat": -26.136607951,
        "lon": 28.341450718
    },
    {
        "facility": "S. A. Redcross Hanger",
        "lat": -33.977856459,
        "lon": 18.594516604
    },
    {
        "facility": "Sabie Hospital",
        "lat": -25.047583333,
        "lon": 30.780683333
    },
    {
        "facility": "Saint Francis Hospital",
        "lat": -30.691388889,
        "lon": 26.693611111
    },
    {
        "facility": "San Michelle Home",
        "lat": -26.17509553,
        "lon": 28.214626229
    },
    {
        "facility": "San Salvadore Home",
        "lat": -26.127805459,
        "lon": 28.045894564
    },
    {
        "facility": "San Serino OAH",
        "lat": -26.068618529,
        "lon": 28.011404876
    },
    {
        "facility": "Sandringham Gardens",
        "lat": -26.143545404,
        "lon": 28.109187389
    },
    {
        "facility": "Sandton  Oncology Centre",
        "lat": -26.097883333,
        "lon": 28.056966667
    },
    {
        "facility": "Sannieshof Hospital",
        "lat": -26.5235,
        "lon": 25.803633333
    },
    {
        "facility": "Sannieshof Old Age Home",
        "lat": -26.533611111,
        "lon": 25.799444444
    },
    {
        "facility": "Sanpark Alcohol and Drug Centre",
        "lat": -26.872983333,
        "lon": 26.661233333
    },
    {
        "facility": "Santos Haven",
        "lat": -34.19186121,
        "lon": 22.116425986
    },
    {
        "facility": "Sappi Clinic Mandini",
        "lat": -29.15035,
        "lon": 31.411666667
    },
    {
        "facility": "Sarah Fox Children's Convalescent Hospital",
        "lat": -33.967466667,
        "lon": 18.53435
    },
    {
        "facility": "Sawas Memorial Hospital",
        "lat": -32.944485589,
        "lon": 24.666021043
    },
    {
        "facility": "Schoongezicht Medi Cross",
        "lat": -26.16375,
        "lon": 27.09605
    },
    {
        "facility": "Schweizer Reneke Hospital",
        "lat": -27.191233333,
        "lon": 25.3291
    },
    {
        "facility": "Scottsdene Day Hospital",
        "lat": -30.288633333,
        "lon": 30.755883333
    },
    {
        "facility": "Sebokeng hospital",
        "lat": -26.607983333,
        "lon": 27.846833333
    },
    {
        "facility": "Sekororo Hospital",
        "lat": -24.168183333,
        "lon": 30.401483333
    },
    {
        "facility": "Senekal Provincial Hospital",
        "lat": -28.32760505,
        "lon": 27.606132002
    },
    {
        "facility": "Sentramed Theatre",
        "lat": -29.0931,
        "lon": 26.2658
    },
    {
        "facility": "Serendipity Frailcare",
        "lat": -33.881666666,
        "lon": 18.577777777
    },
    {
        "facility": "Serene Park Ret. Village",
        "lat": -25.787983683,
        "lon": 28.286337238
    },
    {
        "facility": "Sereno Clinic",
        "lat": -33.724923221,
        "lon": 18.968056261
    },
    {
        "facility": "Seshego Hospital",
        "lat": -23.853888888,
        "lon": 29.397222222
    },
    {
        "facility": "Settlers Hospital",
        "lat": -33.302382971,
        "lon": 26.515390336
    },
    {
        "facility": "Shelly Beach Private",
        "lat": -30.802509886,
        "lon": 30.403579547
    },
    {
        "facility": "Shirnel Clinic c.c.",
        "lat": -33.921533333,
        "lon": 18.43365
    },
    {
        "facility": "Shongwe Hospital",
        "lat": -25.686388889,
        "lon": 31.492222222
    },
    {
        "facility": "Sibaya Clinic",
        "lat": -29.722793135,
        "lon": 31.063825044
    },
    {
        "facility": "Siloah Lutheran Hospital",
        "lat": -27.5785,
        "lon": 31.28925
    },
    {
        "facility": "Siloam Hospital",
        "lat": -22.898101205,
        "lon": 30.195372879
    },
    {
        "facility": "Silver Stream Chalets OAH",
        "lat": -26.094820022,
        "lon": 27.967803955
    },
    {
        "facility": "Silverstream OAH",
        "lat": -26.094820022,
        "lon": 27.967803955
    },
    {
        "facility": "Silverton Medi Cross",
        "lat": -25.731695803,
        "lon": 28.284692004
    },
    {
        "facility": "Sipetu Hospital",
        "lat": -30.903233333,
        "lon": 28.99555
    },
    {
        "facility": "Sir Albert Medical Centre",
        "lat": -26.167366667,
        "lon": 27.719166667
    },
    {
        "facility": "Sir Elliot Hospital",
        "lat": -31.60865,
        "lon": 28.790033333
    },
    {
        "facility": "SIYABUSWA Clinic",
        "lat": -25.120902798,
        "lon": 29.061077363
    },
    {
        "facility": "Smithfield Hospital",
        "lat": -30.213433333,
        "lon": 26.53025
    },
    {
        "facility": "Somerset Hospital",
        "lat": -33.904533333,
        "lon": 18.4163
    },
    {
        "facility": "Somerset OAH",
        "lat": -33.299971242,
        "lon": 26.513643534
    },
    {
        "facility": "Sonstraal Hospital",
        "lat": -33.713216667,
        "lon": 18.99115
    },
    {
        "facility": "Sophia OAH",
        "lat": -29.123234547,
        "lon": 19.396647723
    },
    {
        "facility": "Soshmed Medical Centre",
        "lat": -25.52705,
        "lon": 28.101033333
    },
    {
        "facility": "South Rand Hospital",
        "lat": -26.25245,
        "lon": 28.06255
    },
    {
        "facility": "Southern Cross",
        "lat": -34.104585824,
        "lon": 18.474557789
    },
    {
        "facility": "Soweto Hospice",
        "lat": -26.242947615,
        "lon": 27.949758366
    },
    {
        "facility": "Spatio Clinic",
        "lat": -25.778616667,
        "lon": 28.318883333
    },
    {
        "facility": "Spescare",
        "lat": -34.11230219,
        "lon": 18.838205384
    },
    {
        "facility": "Springs Medi Cross",
        "lat": -26.263231202,
        "lon": 28.439811429
    },
    {
        "facility": "Springsmed Maternity Home",
        "lat": -26.258533333,
        "lon": 28.43375
    },
    {
        "facility": "Sr. Lea Stepdown",
        "lat": -26.102829755,
        "lon": 27.770833126
    },
    {
        "facility": "St Helena Hospital",
        "lat": -27.995601814,
        "lon": 26.709997392
    },
    {
        "facility": "St. Aiden's Mission Hospital",
        "lat": -29.851,
        "lon": 31.009333333
    },
    {
        "facility": "St. Andrews Hospital",
        "lat": -30.569316667,
        "lon": 29.889916667
    },
    {
        "facility": "St. Apollinaris Hospital",
        "lat": -30.027166667,
        "lon": 29.840766667
    },
    {
        "facility": "St. Barnabas Hospital",
        "lat": -31.542683333,
        "lon": 29.017966667
    },
    {
        "facility": "St. Catherine's Life Path Hospital",
        "lat": -29.572994941990835,
        "lon": 30.338504961382768
    },
    {
        "facility": "St. Elizabeth's Hospital",
        "lat": -31.372083333,
        "lon": 29.57355
    },
    {
        "facility": "St. Francis Hospital",
        "lat": -28.230666667,
        "lon": 31.470383333
    },
    {
        "facility": "St. Helena Hospital",
        "lat": -28.012387106,
        "lon": 26.710274341
    },
    {
        "facility": "St. Johns Opthalmic Hospital",
        "lat": -26.261566667,
        "lon": 27.94205
    },
    {
        "facility": "St. Lucy's Home",
        "lat": -31.591166667,
        "lon": 28.774466667
    },
    {
        "facility": "St. Marks Clinic Ladysmith",
        "lat": -28.5534,
        "lon": 29.775416667
    },
    {
        "facility": "St. Mary's Hospital, Durban",
        "lat": -29.841466667,
        "lon": 30.826016667
    },
    {
        "facility": "St. Mary's Hospital, Melmoth",
        "lat": -28.586483333,
        "lon": 31.396533333
    },
    {
        "facility": "St. Marys Step-Down",
        "lat": -29.619247264,
        "lon": 30.401976253
    },
    {
        "facility": "St. Monica's Maternity Hospital",
        "lat": -33.924116667,
        "lon": 18.410933333
    },
    {
        "facility": "St. Patrick's Hospital",
        "lat": -30.864433333,
        "lon": 29.854266667
    },
    {
        "facility": "St. Philomenas Children Ho",
        "lat": -29.827128841,
        "lon": 30.981222244
    },
    {
        "facility": "St. Rita's Hospital",
        "lat": -24.843879153,
        "lon": 29.805377018
    },
    {
        "facility": "St. Vincent's Hospital",
        "lat": -24.88058573,
        "lon": 28.28007082
    },
    {
        "facility": "Standerton Hospital",
        "lat": -26.940166667,
        "lon": 29.2463
    },
    {
        "facility": "Stanger Hospital",
        "lat": -29.335318039,
        "lon": 31.284419629
    },
    {
        "facility": "Stanza Bonate Hosp (Mamli",
        "lat": -25.703626365,
        "lon": 28.393726619
    },
    {
        "facility": "Stellen Oord OAH",
        "lat": -33.940580682,
        "lon": 18.827874075
    },
    {
        "facility": "Stellenbosch Hospital",
        "lat": -33.930683333,
        "lon": 18.8702
    },
    {
        "facility": "Sterkfontein Hospital",
        "lat": -26.059633333,
        "lon": 27.7566
    },
    {
        "facility": "Sterkstroom Hospital",
        "lat": -31.557066667,
        "lon": 26.55245
    },
    {
        "facility": "Steve Biko Hospital",
        "lat": -25.730683333,
        "lon": 28.201216667
    },
    {
        "facility": "Steynsburg Hospital",
        "lat": -31.295906869,
        "lon": 25.825049377
    },
    {
        "facility": "Stikland Psychiatric Hospital",
        "lat": -33.891233333,
        "lon": 18.653516667
    },
    {
        "facility": "Stoffel Kotze Hospital",
        "lat": -30.22330278,
        "lon": 26.523117807
    },
    {
        "facility": "STRANDFONTEIN POLICE STATION",
        "lat": -34.0742,
        "lon": 18.5755
    },
    {
        "facility": "Stutterheim Hospital",
        "lat": -32.571066667,
        "lon": 27.429783333
    },
    {
        "facility": "Summerfield Lodge",
        "lat": -26.03865663,
        "lon": 27.979770663
    },
    {
        "facility": "Summervale Care Centre",
        "lat": -34.148608429,
        "lon": 18.858433275
    },
    {
        "facility": "Sunday's Valley Hospital",
        "lat": -33.3989,
        "lon": 25.443283333
    },
    {
        "facility": "Sundumbili Clinic",
        "lat": -29.136388889,
        "lon": 31.400936305
    },
    {
        "facility": "Sunfield Home",
        "lat": -29.480716667,
        "lon": 30.227083333
    },
    {
        "facility": "Sunningdale Hospital",
        "lat": -26.846666667,
        "lon": 26.669166667
    },
    {
        "facility": "Sunnyside Gynaecological Hospital",
        "lat": -25.755932901,
        "lon": 28.204898569
    },
    {
        "facility": "Sunset Valley",
        "lat": -26.004717016,
        "lon": 28.107396746
    },
    {
        "facility": "Sunshine Hospital",
        "lat": -26.213017569,
        "lon": 28.300987764
    },
    {
        "facility": "Swartklip Union Hospital",
        "lat": 24.9459,
        "lon": 27.1677
    },
    {
        "facility": "Swartland Hospital",
        "lat": -33.453917292,
        "lon": 18.723378757
    },
    {
        "facility": "Swartruggens Clinic",
        "lat": -25.632476423,
        "lon": 26.683064061
    },
    {
        "facility": "Swellendam Hospital",
        "lat": -34.023735161,
        "lon": 20.448864904
    },
    {
        "facility": "Tableview Medi Cross",
        "lat": -33.824427904,
        "lon": 18.490579373
    },
    {
        "facility": "Tambo Memorial Hospital",
        "lat": -26.218432629,
        "lon": 28.245376514
    },
    {
        "facility": "Tamboti Lodge",
        "lat": -24.698733333,
        "lon": 26.194766667
    },
    {
        "facility": "Tara H. Moross Centre Hospital",
        "lat": -26.105733333,
        "lon": 28.037083333
    },
    {
        "facility": "Tarentaal Village",
        "lat": -26.128753284,
        "lon": 28.124208242
    },
    {
        "facility": "Taung Community Hospital",
        "lat": -27.538043036,
        "lon": 24.790664231
    },
    {
        "facility": "Tayler Bequest Hospital",
        "lat": -30.34075,
        "lon": 28.800983333
    },
    {
        "facility": "Temba SANTA TB Hospital",
        "lat": -33.310835754,
        "lon": 26.547650408
    },
    {
        "facility": "Tembisa Hospital",
        "lat": -25.9830396,
        "lon": 28.238771813
    },
    {
        "facility": "test",
        "lat": -26.033055319,
        "lon": 28.067303782
    },
    {
        "facility": "Thabamoopo Hospital",
        "lat": -24.260466667,
        "lon": 29.547866667
    },
    {
        "facility": "Thabazimbi Hospital",
        "lat": -24.599079505,
        "lon": 27.406119129
    },
    {
        "facility": "Thafalofefe Hospital",
        "lat": -32.506566667,
        "lon": 28.317816667
    },
    {
        "facility": "Thatchfield Hill Estate",
        "lat": -25.896367131,
        "lon": 28.107563958
    },
    {
        "facility": "The Childrens Right Centre",
        "lat": -29.859424926,
        "lon": 31.007942446
    },
    {
        "facility": "Themba Hospital",
        "lat": -25.344921937,
        "lon": 31.12267679
    },
    {
        "facility": "Theunis Fichardt Hospitium",
        "lat": -23.891726977473425,
        "lon": 29.458707141388963
    },
    {
        "facility": "THULAMAHSHE HOSP",
        "lat": -24.727222222,
        "lon": 31.207222222
    },
    {
        "facility": "Thulasizwe Hospital",
        "lat": -28.230716667,
        "lon": 31.470333333
    },
    {
        "facility": "Thusanong Hospital",
        "lat": -27.862766667,
        "lon": 26.711483333
    },
    {
        "facility": "Thusong Community Hospital",
        "lat": -26.152683333,
        "lon": 26.17035
    },
    {
        "facility": "Tintswalo Hospital",
        "lat": -24.593055556,
        "lon": 31.059722222
    },
    {
        "facility": "TK Maternity Hospital",
        "lat": -26.316996318,
        "lon": 28.157601183
    },
    {
        "facility": "Tokai Medi Cross",
        "lat": -34.064416667,
        "lon": 18.451033333
    },
    {
        "facility": "Tonga Hospital",
        "lat": -25.695158977,
        "lon": 31.787988549
    },
    {
        "facility": "Tower Hospital",
        "lat": -32.77015,
        "lon": 26.629533333
    },
    {
        "facility": "Towers Clinic",
        "lat": -26.2,
        "lon": 28.044633333
    },
    {
        "facility": "Town Hill Hospital",
        "lat": -29.588883333,
        "lon": 30.372416667
    },
    {
        "facility": "Trianon Health Care Centre",
        "lat": -34.028916667,
        "lon": 18.455133333
    },
    {
        "facility": "Triomf Medi Cross",
        "lat": -26.174033333,
        "lon": 27.979366667
    },
    {
        "facility": "Tshepong Hospital",
        "lat": -26.886614721,
        "lon": 26.61496555
    },
    {
        "facility": "Tshilidzini Hospital",
        "lat": -22.993875619,
        "lon": 30.415272693
    },
    {
        "facility": "Tshwaraganyo hosp",
        "lat": -27.306628885,
        "lon": 23.344634757
    },
    {
        "facility": "Tswane District Hospital",
        "lat": -25.732570483,
        "lon": 28.201891877
    },
    {
        "facility": "Twilanga Ret. Village",
        "lat": -29.725762146,
        "lon": 31.07175408
    },
    {
        "facility": "Tygerberg Hospital",
        "lat": 33.9113,
        "lon": 18.6122
    },
    {
        "facility": "UCT Private Academic Hospital",
        "lat": -33.941883333,
        "lon": 18.461816667
    },
    {
        "facility": "Uitenhage Provincial Hospital",
        "lat": -33.740533225,
        "lon": 25.406246161
    },
    {
        "facility": "Umlangi Hospital",
        "lat": -30.549413965,
        "lon": 27.464752764
    },
    {
        "facility": "Umphumulo  Hospital",
        "lat": -29.134033333,
        "lon": 31.038666667
    },
    {
        "facility": "Umtata Sub-Acute",
        "lat": -31.58894446,
        "lon": 28.785571238
    },
    {
        "facility": "Umzimkulu Hospital",
        "lat": -30.265966667,
        "lon": 29.935766667
    },
    {
        "facility": "Unicare",
        "lat": -23.90532011,
        "lon": 29.46229869
    },
    {
        "facility": "Uniondale Hospital",
        "lat": -33.659542448,
        "lon": 23.125544917
    },
    {
        "facility": "Unique Care",
        "lat": -23.903572292,
        "lon": 29.462427347
    },
    {
        "facility": "Unit 10 Clinic",
        "lat": -29.707980177,
        "lon": 30.991968833
    },
    {
        "facility": "Universitas Private Hospital",
        "lat": -29.1177,
        "lon": 26.185383333
    },
    {
        "facility": "Usher Memorial Hospital",
        "lat": -30.544283333,
        "lon": 29.41545
    },
    {
        "facility": "Valhalla Park Clinic",
        "lat": -33.956,
        "lon": 18.571
    },
    {
        "facility": "Valkenberg Hospital",
        "lat": -33.93885,
        "lon": 18.4814
    },
    {
        "facility": "Valley Trust Clinic, The",
        "lat": -29.753033333,
        "lon": 30.74345
    },
    {
        "facility": "Van Velden Hospital",
        "lat": -23.834661152,
        "lon": 30.164607297
    },
    {
        "facility": "Vanguard Day Hospital",
        "lat": -33.948179801,
        "lon": 18.543379429
    },
    {
        "facility": "Vantec Clinic",
        "lat": -24.823994599,
        "lon": 30.112883232
    },
    {
        "facility": "Ventersdorp Hospital",
        "lat": -26.310833333,
        "lon": 26.818333333
    },
    {
        "facility": "Vereeniging Hospital",
        "lat": -26.637933333,
        "lon": 27.931016667
    },
    {
        "facility": "Vereeniging Medi Cross",
        "lat": -26.660083333,
        "lon": 27.970416667
    },
    {
        "facility": "Victoria Hospital, Wynberg",
        "lat": -34.011416667,
        "lon": 18.459
    },
    {
        "facility": "Victoria Private Hospital",
        "lat": -25.85951459,
        "lon": 25.641461626
    },
    {
        "facility": "Victoria West Private Hospital",
        "lat": -31.404633333,
        "lon": 23.11715
    },
    {
        "facility": "Villa Cortona",
        "lat": -33.812458683,
        "lon": 18.67729817
    },
    {
        "facility": "Village of Happiness",
        "lat": -30.847590003,
        "lon": 30.371340156
    },
    {
        "facility": "Village View Medical Centre",
        "lat": -26.184533333,
        "lon": 28.136133333
    },
    {
        "facility": "Virginia Airport",
        "lat": -29.775643532,
        "lon": 31.055161525
    },
    {
        "facility": "Virginia Provincial Hospital",
        "lat": -28.109216667,
        "lon": 26.8578
    },
    {
        "facility": "Vista Nova Centre",
        "lat": -26.295583333,
        "lon": 28.496266667
    },
    {
        "facility": "Vista Private Psychiatric Clinic",
        "lat": -25.843611111,
        "lon": 28.194166667
    },
    {
        "facility": "Volksrust Provincial Hospital",
        "lat": -27.365583333,
        "lon": 29.8805
    },
    {
        "facility": "Voortrekker Hospital, Potgietersrus",
        "lat": -24.195934178,
        "lon": 29.01379336
    },
    {
        "facility": "Vosburg Hospital",
        "lat": -30.575683333,
        "lon": 22.879616667
    },
    {
        "facility": "Vredenburg Hospital",
        "lat": -32.913394178,
        "lon": 17.991159888
    },
    {
        "facility": "Vredendal Hospital",
        "lat": -31.669133963,
        "lon": 18.504271536
    },
    {
        "facility": "Vryburg Private Hospital",
        "lat": -26.946701104,
        "lon": 24.721890973
    },
    {
        "facility": "Vryheid Hospital",
        "lat": -27.759316667,
        "lon": 30.796566667
    },
    {
        "facility": "Vue De Cap",
        "lat": -33.829713179,
        "lon": 18.4949499
    },
    {
        "facility": "Vuyani Clinic",
        "lat": -33.984683333,
        "lon": 18.567416667
    },
    {
        "facility": "Walmer P.E. Medi Cross",
        "lat": -33.9909,
        "lon": 25.5515
    },
    {
        "facility": "Warmbaths Hospital",
        "lat": -24.884866667,
        "lon": 28.288766667
    },
    {
        "facility": "WarrenMed Medical Centre",
        "lat": -28.110555556,
        "lon": 24.849722222
    },
    {
        "facility": "Warrenton Hospital",
        "lat": -28.112433333,
        "lon": 24.848266667
    },
    {
        "facility": "Warrior Moth OAH",
        "lat": -26.042,
        "lon": 27.838
    },
    {
        "facility": "Waterfall Gardens",
        "lat": -29.750612944,
        "lon": 30.829056478
    },
    {
        "facility": "Waterval Boven Hospital",
        "lat": -25.648223874,
        "lon": 30.338818247
    },
    {
        "facility": "Wege Hospital",
        "lat": -29.623216667,
        "lon": 24.088533333
    },
    {
        "facility": "Welbedacht Rehab Centre",
        "lat": -33.981854161,
        "lon": 25.562518388
    },
    {
        "facility": "Welkom Medical centre",
        "lat": -27.991666667,
        "lon": 26.733888889
    },
    {
        "facility": "Wembley House",
        "lat": -29.592695214,
        "lon": 30.3658425
    },
    {
        "facility": "Wentworth Hospital",
        "lat": -29.931383333,
        "lon": 30.989616667
    },
    {
        "facility": "Wesfleur Hospital",
        "lat": -33.564583333,
        "lon": 18.4948
    },
    {
        "facility": "Weskoppies Hospital",
        "lat": -25.76305,
        "lon": 28.160616667
    },
    {
        "facility": "West End Hospital",
        "lat": -28.736516667,
        "lon": 24.737966667
    },
    {
        "facility": "West Vaal Hospital",
        "lat": -26.962222222,
        "lon": 26.673333333
    },
    {
        "facility": "Westerbloem Old Age Hom",
        "lat": -29.139444444,
        "lon": 26.151944444
    },
    {
        "facility": "Westering P.E. Medi Cross",
        "lat": -33.9453,
        "lon": 25.521033333
    },
    {
        "facility": "Western Deep Levels Hospital",
        "lat": -26.358583333,
        "lon": 27.39445
    },
    {
        "facility": "Western province rehab",
        "lat": -34.024180729,
        "lon": 18.62503172
    },
    {
        "facility": "Westfort Hospital",
        "lat": -25.73455,
        "lon": 28.086683333
    },
    {
        "facility": "WESTVILLE (Ambulances and Fire Brigades)",
        "lat": -29.8336,
        "lon": 30.9113
    },
    {
        "facility": "Westways Private Hospital",
        "lat": -33.9481,
        "lon": 25.55845
    },
    {
        "facility": "Wilhelm Stahl Hospital",
        "lat": -31.491316667,
        "lon": 24.999983333
    },
    {
        "facility": "Willem Cruywagen Hospital",
        "lat": -26.219813547,
        "lon": 28.164284162
    },
    {
        "facility": "Williston Hospital",
        "lat": -31.340666667,
        "lon": 20.917466667
    },
    {
        "facility": "Willow Haven",
        "lat": -25.762964166,
        "lon": 28.307072484
    },
    {
        "facility": "Willowmore Hospital",
        "lat": -33.300238757,
        "lon": 23.476816517
    },
    {
        "facility": "Wilmedpark Hospital",
        "lat": -26.830609854,
        "lon": 26.678093574
    },
    {
        "facility": "Winburg Provincial Hospital",
        "lat": -28.517744927,
        "lon": 27.011135281
    },
    {
        "facility": "Windmill Park Estate",
        "lat": -26.30348611,
        "lon": 28.260614117
    },
    {
        "facility": "Wine Garden",
        "lat": -26.001848172,
        "lon": 28.127583926
    },
    {
        "facility": "Winmed Stepdown",
        "lat": -25.795833333,
        "lon": 28.301666667
    },
    {
        "facility": "Winston Park",
        "lat": -29.815901556,
        "lon": 30.78814536
    },
    {
        "facility": "Wisani Clinic",
        "lat": -25.6016,
        "lon": 27.997033333
    },
    {
        "facility": "Witbank Hospital",
        "lat": -25.876604938,
        "lon": 29.227518934
    },
    {
        "facility": "Witfield OAH",
        "lat": -26.184109677,
        "lon": 28.201540679
    },
    {
        "facility": "Witkoppen Clinic",
        "lat": -26.010833333,
        "lon": 28.010277778
    },
    {
        "facility": "Witpoort Hospital",
        "lat": -23.334360553,
        "lon": 28.010264431
    },
    {
        "facility": "Witrand Rehab",
        "lat": -26.685277778,
        "lon": 27.069444444
    },
    {
        "facility": "Wolmaransstad Hospital",
        "lat": -27.188908556,
        "lon": 25.98256505
    },
    {
        "facility": "Wolwekrans Hospital",
        "lat": -25.87315,
        "lon": 29.251133333
    },
    {
        "facility": "Woodstock Hospital",
        "lat": -33.931866667,
        "lon": 18.4487
    },
    {
        "facility": "Wylie House Youth Centre",
        "lat": -29.856706858,
        "lon": 30.987044909
    },
    {
        "facility": "Wynberg Hospital",
        "lat": -34.00505,
        "lon": 18.468833333
    },
    {
        "facility": "Zambesi Retirement Village",
        "lat": -25.679567885,
        "lon": 28.25879425
    },
    {
        "facility": "Zandfontein Clinic",
        "lat": -26.079483333,
        "lon": 28.064366667
    },
    {
        "facility": "Zastron Povincial Hospital",
        "lat": -30.30202609,
        "lon": 27.082151886
    },
    {
        "facility": "Zeerust Hospital",
        "lat": -25.543129692,
        "lon": 26.066111191
    },
    {
        "facility": "Zithulele Hospital",
        "lat": -31.981933333,
        "lon": 29.149716667
    },
    {
        "facility": "Zoutpansberg Hospital",
        "lat": -23.04071627,
        "lon": 29.89735914
    },
    {
        "facility": "Zoutpansberg Private Hosp",
        "lat": -23.040570741,
        "lon": 29.897181296
    },
    {
        "facility": "Zuid Afrikaans Hospital",
        "lat": -25.761388889,
        "lon": 28.206666667
    }
];

export const MEDICAL_AIDS = [
    "Other (details in notes)",
    "Fixed Rate Transfer",
    "WCA",
    "Private",
    "AA Mayday",
    "ADT",
    "AECI Medical Aid Society",
    "Affinity Health",
    "Afrox Medical Aid Society",
    "Alliance Internatational (Aims)",
    "Alliance Midmed",
    "Altron",
    "Anglo Medical",
    "Anglovaal Group Medical",
    "Bankmed",
    "Barloworld",
    "Bestmed",
    "BIMAF",
    "BMW Employees Medical Aid",
    "Bomaid (Botswana Medical Aid)",
    "Bonitas Medical Fund",
    "BP Medical Aid",
    "Building & Construction Industry Medical Aid",
    "Bupa International",
    "CAMAF",
    "Cape Medical Plan",
    "Cigna International",
    "CIMS",
    "COMMED",
    "Compcare Wellness Medical",
    "Customer Loyalty Consultants",
    "De Beers Benefit Society",
    "Discovery Health",
    "Engen Medical Benefit Fund",
    "Europ Assistance",
    "Fedhealth Medical Scheme",
    "Fish-Med",
    "Flexicare",
    "Food Workers Medical",
    "GEMS",
    "Genesis Medical",
    "Getsavvi Health",
    "Glencore Medical Scheme",
    "Gold Fields Health Services",
    "Golden Arrows Employees'",
    "Grintek Electronics Medical",
    "Harmony Gold Mining Company Ltd",
    "Health Squared Medical Scheme",
    "Health4Me",
    "Horizon Medical Scheme",
    "Hosmed Medical Aid Scheme",
    "Impala Medical Plan",
    "Imperial Group Medical Scheme",
    "JD Group",
    "Kaelo Health",
    "Keyhealth",
    "La-Health Medical Scheme",
    "Libcare Medical Scheme",
    "Liberty Medical Scheme",
    "LimDoH",
    "Lonmin Medical Scheme",
    "Makoti Medical Scheme",
    "Malcor Medical Scheme",
    "Mamoth Health Plan",
    "Massmart Health Plan",
    "Mbmed Medical Aid Fund",
    "Medihelp",
    "Medimed Medical Scheme",
    "Medipos Medical Scheme",
    "Medshield Medical Scheme",
    "Metrocare",
    "Metropolitan Medical Scheme",
    "Momentum Health",
    "Motohealth Care",
    "MPDoH",
    "Myhealth",
    "Namibia Health Plan",
    "Nammed Medical Aid Fund",
    "Naspers Medical Fund",
    "NBCRFLI",
    "Nedgroup Medical Aid",
    "Netcare Medical Scheme",
    "NUFAWSA",
    "Old Mutual Staff Medical Aid",
    "Optimum Medical Scheme (Opmed)",
    "Parmed Medical Aid",
    "PG Bison Medical Aid",
    "PG Group Medical Scheme",
    "Pharos Medical Plan",
    "Pick N Pay Medical",
    "Platinum Health",
    "Polmed",
    "Profmed",
    "Quantum Medical Aid",
    "Rand Water Medical",
    "Regular Forces Medical Continuation Fund",
    "Remedi Medical Aid",
    "Resolution Health Medical",
    "Retail Medical",
    "Rhodes University Medical",
    "RUMED",
    "SA National Defense Force",
    "SABC Medical Aid",
    "Samwumed",
    "Sasol Non-Iod",
    "Sasolmed",
    "Sedmed",
    "SEHC",
    "Selfmed Medical",
    "Sibanye Gold Limited",
    "Sisonke Health Medical",
    "Sizwe Medical",
    "South African Breweries Medical",
    "Spectramed",
    "Suremed Health",
    "Swazimed",
    "TFG Medical Aid",
    "Thebemed",
    "Tiger Brands Medical",
    "Topmed Medical",
    "Transmed Medical Fund",
    "Travel Insurance",
    "Tsogo Sun Group Medical Scheme",
    "Umvuzo Health",
    "University of Kwa-Zulu Natal Medical",
    "University of The Witwatersrand - Johannesburg Staff Medical Aid Fund",
    "WCMAS",
    "Witbank Coalfields Medical Aid",
    "Wooltru Healthcare",
    "Yebo-Med",
]